import React, { useContext, useState } from 'react';
import { WorkshopContext } from '../../../contexts/WorkshopContext';
import { ParticipantDetailsContext } from '../../../contexts/ParticipantDetailsContext';
import { toast } from 'react-toastify';
import { Button } from '@material-ui/core';

function ActivePoll(props) {
  const { activePoll, currentWorkshop } = useContext(WorkshopContext);
  const { participantDetails } = useContext(ParticipantDetailsContext);

  const [loading, setLoading] = useState(0);

  //Submit Poll Answer
  const submitPollAnswer = (pollId, optionId) => {
    setLoading(optionId);
    fetch(process.env.REACT_APP_API_URL + '/submit-poll-answer', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + participantDetails?.token,
      },
      body: JSON.stringify({
        pollId,
        optionId,
      }),
    })
      .then(res => {
        if (!res.ok) {
          setLoading(0);
          toast.error('Something went wrong! Please, Try again later.');
        }
        return res.json();
      })
      .then(data => {});
  };

  return (
    <div className="bg-white py-2 text-center" style={{ borderRadius: '5px' }}>
      <span className="pollQuestion">{activePoll.title}</span>
      <div className="pollSticker py-2">
        {activePoll.options.map((option, key) => (
          <>
            {currentWorkshop &&
              currentWorkshop.answered_poll_ids &&
              currentWorkshop.answered_poll_ids.includes(activePoll.id) && (
                <Button className="pollOption" key={option.id}>
                  {option.text}
                  {activePoll.answers_results[key] >= 50 ? (
                    <span className="pollOptionResult text-success">{`${activePoll.answers_results[key]}%`}</span>
                  ) : (
                    <span className="pollOptionResult text-warning">{`${activePoll.answers_results[key]}%`}</span>
                  )}
                </Button>
              )}

            {currentWorkshop &&
              currentWorkshop.answered_poll_ids &&
              !currentWorkshop.answered_poll_ids.includes(activePoll.id) && (
                <Button
                  className="pollOption"
                  key={option.id}
                  onClick={() => submitPollAnswer(activePoll.id, option.id)}
                  disabled={loading === option.id}
                >
                  {option.text}
                  {loading === option.id ? <span className="ml-1 spinner spinner-dark"></span> : null}
                </Button>
              )}
          </>
        ))}
      </div>
    </div>
  );
}

export default ActivePoll;
