import React, { useState, useEffect } from 'react';
import useParticipantDetails from '../../../hooks/useParticipantDetails';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

async function loginUser(credentials) {
  return fetch(process.env.REACT_APP_API_URL + '/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(credentials),
  })
    .then(response => {
      return response.json();
    })
    .then(result => {
      if (result.errors !== undefined) {
        return result.errors;
      }
      return result.data;
    });
}

const Login = () => {
  const { setParticipantDetails } = useParticipantDetails();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [loading, setLoading] = useState(false);

  /* Development purpose only */
  const [usersForLogin, setUsersForLogin] = useState([]);
  useEffect(() => {
    sessionStorage.clear();
    localStorage.clear();

    fetch(process.env.REACT_APP_API_URL + '/get-login-participants', { method: 'GET' })
      .then(response => response.json())
      .then(result => setUsersForLogin(result.data));
  }, []);
  /* End: Development purpose only */

  const handleSubmit = async e => {
    setLoading(true);
    e.preventDefault();
    const participantDetails = await loginUser({
      email,
      password,
    });
    if (participantDetails !== undefined && participantDetails.token !== undefined) {
      toast.success('Logged In Success!');
      setParticipantDetails(participantDetails);
      setTimeout(() => {
        setLoading(false);
        window.location.href = '/';
      }, 2500);
    } else {
      setLoading(false);
      var errors = participantDetails;
      Object.keys(errors).map((error, index) => toast.error(errors[error][index]));
    }
  };

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">Login Account</h3>
        <p className="text-muted font-weight-bold">Enter your username and password</p>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form className="form fv-plugins-bootstrap fv-plugins-framework" onSubmit={handleSubmit}>
        {/*<div className="mb-10 alert alert-custom alert-light-info alert-dismissible">
            <div className="alert-text ">
                <p>
                    Student Login: <strong>student@test.com</strong> and password <strong>password</strong> to continue.
                </p>
                <p>
                    Educator Login: <strong>admin.educator@test.com</strong> and password <strong>password</strong> to
                    continue.
                </p>
            </div>
        </div>*/}

        {/*<div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Email"
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 `}
            onChange={e => setEmail(e.target.value)}
          />
        </div>*/}

        {/*Development purpose only*/}
        <div className="form-group fv-plugins-icon-container">
          <select
            placeholder="Email"
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 `}
            onChange={e => setEmail(e.target.value)}
            defaultValue=""
          >
            <option value="" disabled>
              -- Select Educator Or Student
            </option>
            {usersForLogin && usersForLogin.educators && (
              <optgroup label="Educators" style={{ color: 'black' }}>
                {usersForLogin.educators.map(educator => (
                  <option value={educator.email} key={educator.id}>
                    {educator.name} ({educator.email})
                  </option>
                ))}
              </optgroup>
            )}
            {usersForLogin && usersForLogin.students && (
              <optgroup label="Students" style={{ color: 'black' }}>
                {usersForLogin.students.map(student => (
                  <option value={student.email} key={student.id}>
                    {student.name} ({student.email})
                  </option>
                ))}
              </optgroup>
            )}
          </select>
        </div>
        {/*END:Development purpose only*/}

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6`}
            name="password"
            onChange={e => setPassword(e.target.value)}
          />
        </div>
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <button
            id="kt_login_signin_submit"
            type="submit"
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
            disabled={loading}
          >
            <span>Sign In</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>
      {/*end::Form*/}
      <ToastContainer autoClose={2500} />
    </div>
  );
};

export default Login;
