import React, { useContext, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { Grid, Container } from '@material-ui/core';
//css
import 'react-toastify/dist/ReactToastify.css';
import '../../assets/screen-design.css';
// Hooks
import useCurrentMeetWorkshopData from '../hooks/useCurrentMeetWorkshopData';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import useParticipants from '../../hooks/useParticipants/useParticipants';
// Components
import HeaderComponent from '../components/HeaderComponent';
import SidebarComponent from '../components/SidebarComponent';
import MainSectionComponent from '../components/MainSectionComponent';
// Contexts
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { CurrentActiveRoomContext } from '../contexts/CurrentActiveRoomContext';
import { WorkshopContext } from '../contexts/WorkshopContext';
import { GlobalRoomContext } from '../contexts/GlobalRoomContext';
import { HelperContext } from '../contexts/HelperContext';

export function VideoApp() {
  /**
   *  Reload & Leave Application Confirmation Dialog
   *  Preventing User From Reloading & Leave Application
   * @param event
   * @returns {string}
   */
  window.onbeforeunload = event => {
    window.location.reload(false);
    const e = event || window.event;
    e.preventDefault();
    if (e) {
      e.returnValue = '';
    }
    return '';
  };
  /** ================================ */

  /** CONTEXTS & HOOKS & STATES */
  const { room } = useVideoContext();
  const { currentWorkshop, setCurrentWorkshop } = useContext(WorkshopContext);
  const { globalRoomName, globalSubRoomName, setRoomObject, changeRoom, peermode } = useContext(GlobalRoomContext);
  const { getKeyFromObject } = useContext(HelperContext);
  const { setJoinedParticipants, setActiveParticipantsGrid } = useContext(CurrentActiveRoomContext);

  const { data: activeWorkshop } = useCurrentMeetWorkshopData();

  const roomState = useRoomState();
  const participants = useParticipants();
  const isReconnecting = roomState === 'reconnecting';

  /** ================================ */

  /**
   * Setting Current Workshop Globally Into WorkshopContext
   * ======================================================
   */
  useEffect(() => {
    if (activeWorkshop) setCurrentWorkshop(activeWorkshop);
  }, [activeWorkshop]);
  /** ====================================================== */

  /**
   * Change Main & Breakout Rooms Automatically
   * ==========================================
   */
  useEffect(() => {
    //Joining Break Out Room Automatically
    if (currentWorkshop && peermode === 1 && !globalSubRoomName) {
      if (
        currentWorkshop.enrolled_table_name !== null &&
        currentWorkshop.enrolled_table_name !== '' &&
        !currentWorkshop.enrolled_table_name.includes('unJoined')
      ) {
        changeRoom(globalRoomName + ' | ' + currentWorkshop.enrolled_table_name);
      }
    }
    //Joining Main Room Automatically
    if (currentWorkshop && peermode === 0 && globalSubRoomName) changeRoom(globalRoomName);
  }, [currentWorkshop]);
  /** ========================================== */

  /**
   * Setting Joined Participants && Active Participant Grid Into CurrentActiveRoomContext
   * ====================================================================================
   */
  useEffect(() => {
    // Setting Joined Participants
    if (participants && participants.length) {
      setJoinedParticipants(getKeyFromObject(participants, ['identity']));
    } else {
      setJoinedParticipants([]);
    }

    // For auto close Participants Grid when no other exists
    if (!participants.length) setActiveParticipantsGrid(false);
  }, [participants]);
  /** ==================================================================================== */

  /**
   * Setting Room Object Globally into GlobalRoomContext
   * ===================================================
   */
  useEffect(() => {
    if (room) setRoomObject(room);
  }, [room]);
  /** =================================================== */

  return (
    <div className="custom-video-screen">
      {/* Toast Message Container */}
      <ToastContainer autoClose={2000} />
      {/* END: Toast Message Container */}
      {/* Header Buttons */}
      <HeaderComponent />
      {/* END: Header Buttons */}
      <Container maxWidth="xl" fluid="true">
        <Grid container spacing={3}>
          {/* Sidebar */}
          <SidebarComponent />
          {/* END: Sidebar */}
          {/* Main Section Component */}
          <MainSectionComponent isReconnecting={isReconnecting} />
          {/* END: Main Sections Component */}
        </Grid>
      </Container>
    </div>
  );
}
