import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Card, CardContent, Divider, Typography } from '@material-ui/core';
import { toast } from 'react-toastify';
import { ParticipantDetailsContext } from '../../../contexts/ParticipantDetailsContext';
import { WorkshopContext } from '../../../contexts/WorkshopContext';
import {GlobalRoomContext} from "../../../contexts/GlobalRoomContext";

function SliderManage({ openSlider }) {
  const { participantDetails } = useContext(ParticipantDetailsContext);
  const { currentWorkshop, sliders } = useContext(WorkshopContext);
  const { hiddenRoomParticipants } = useContext(GlobalRoomContext);

  // Slider
  const [loading, setLoading] = useState(false);
  const [statusLoading, setStatusLoading] = useState(true);
  const [sliderQue, setSliderQue] = useState('');
  const handleSliderQueChange = useCallback(event => {
    setSliderQue(event.target.value);
  }, []);

  useEffect(() => {
    setLoading(false);
    setStatusLoading(false);
  }, [sliders]);

  //Create Slider
  const CreateSlider = () => {
    setLoading(true);
    fetch(process.env.REACT_APP_API_URL + '/create-slider', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + participantDetails?.token,
      },
      body: JSON.stringify({
        workshopId: currentWorkshop.id,
        sliderQue,
      }),
    })
      .then(res => {
        if (!res.ok) {
          setLoading(false);
          toast.error('Please fill all the inputs!');
        }
        return res.json();
      })
      .then(data => {});
  };

  //Toggle Slider Status
  const toggleSliderStatus = (sliderId, status) => {
    setStatusLoading(true);
    fetch(process.env.REACT_APP_API_URL + '/active-slider', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + participantDetails?.token,
      },
      body: JSON.stringify({
        workshopId: currentWorkshop.id,
        sliderId,
        status,
      }),
    })
      .then(res => {
        if (!res.ok) {
          setStatusLoading(false);
          toast.error('Something went wrong! Please, Try again later.');
        }
        return res.json();
      })
      .then(data => {});
  };

  //Destroy Slider
  const destroySlider = sliderId => {
    setLoading(true);
    fetch(process.env.REACT_APP_API_URL + '/delete-slider', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + participantDetails?.token,
      },
      body: JSON.stringify({
        sliderId,
      }),
    })
      .then(res => {
        if (!res.ok) {
          setLoading(false);
          toast.error('Something went wrong! Please, Try again later.');
        }
        return res.json();
      })
      .then(data => {});
  };

  return (
    <>
      {openSlider && (
        <div className="bg-white p-2" style={{ borderRadius: '8px' }}>
          {sliders && !sliders.length && (
            <>
              <div className="form-group row mt-1 mb-0 justify-content-between">
                <label className="col-form-label col-4">Slider Question</label>
                <div className="col-7 justify-content-between">
                  <input
                    type="text"
                    placeholder="Slider Question"
                    className="form-control form-control-sm"
                    value={sliderQue}
                    onChange={handleSliderQueChange}
                  />
                </div>
              </div>

              <div className="form-group row my-1 justify-content-between">
                <label className="col-4 col-form-label"></label>
                <div className="col-7 justify-content-between">
                  <Button
                    className="btn btn-success font-weight-bold px-9"
                    onClick={() => CreateSlider()}
                    disabled={loading}
                  >
                    <span>Create Slider</span>
                    {loading && <span className="ml-3 spinner spinner-white"></span>}
                  </Button>
                </div>
              </div>
            </>
          )}

          {sliders && sliders.length ? (
            <>
              <Divider />
              <Card style={{ backgroundColor: 'rgb(44 188 152)', boxShadow: '1px 1px 5px grey' }} className="mb-2">
                <CardContent style={{ padding: '7px' }}>
                  <div className="row">
                    <div style={{ textAlign: 'center' }} className="col-9">
                      <Typography variant="paragraph" style={{ position: 'relative', color: 'white' }}>
                        Slider / Result
                      </Typography>
                    </div>
                    <div style={{ textAlign: 'center' }} className="col-3">
                      <Typography variant="paragraph" style={{ position: 'relative', color: 'white' }}>
                        Actions
                      </Typography>
                    </div>
                  </div>
                </CardContent>
              </Card>

              {sliders.map(slider => (
                <Card
                  style={{ backgroundColor: 'rgb(244 244 244)', boxShadow: '1px 1px 5px grey' }}
                  className="mb-2"
                  key={slider.id}
                >
                  <CardContent style={{ padding: '7px' }}>
                    <div className="row">
                      <div style={{ textAlign: 'center' }} className="col-9">
                        <span className="sliderQuestion">{slider.title}</span>
                        <div className="sliderSticker">
                          <div className="sliderResult">
                            <div
                              className="sliderDots sliderAverageAnswer ml-0"
                              style={{ left: `${slider.average}%` }}
                            ></div>
                            <div className="slider-fill" style={{ width: `${slider.average}%` }}></div>
                          </div>
                        </div>
                      </div>
                      <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} className="col-3">
                        <div className="action" style={{ display: 'inherit' }}>
                          {slider.is_active ? (
                            <a className="btn btn-sm btn-clean btn-icon" onClick={() => toggleSliderStatus(slider.id, 0)}>
                              {statusLoading && (
                                <span className="spinner spinner-gray mr-2" style={{ fontSize: '0.7rem' }}></span>
                              )}
                              {!statusLoading && <i className="fas fa-pause text-warning" style={{ fontSize: '1rem' }} />}
                            </a>
                          ) : (
                            <a className="btn btn-sm btn-clean btn-icon" onClick={() => toggleSliderStatus(slider.id, 1)}>
                              {statusLoading && (
                                <span className="spinner spinner-gray mr-2" style={{ fontSize: '0.7rem' }}></span>
                              )}
                              {!statusLoading && <i className="fas fa-play text-success" style={{ fontSize: '1rem' }} />}
                            </a>
                          )}
                          <a className="btn btn-sm btn-clean btn-icon" onClick={() => destroySlider(slider.id)}>
                            {loading && (
                              <span className="spinner spinner-gray mr-4" style={{ fontSize: '0.7rem' }}></span>
                            )}
                            {!loading && <i className="fas fa-trash text-danger" style={{ fontSize: '1rem' }} />}
                          </a>
                        </div>
                        <div className="result">
                          <span style={{ fontWeight: '600', color: '#bababa' }}>{slider.total_answers} / {hiddenRoomParticipants.length}</span>
                        </div>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              ))}
            </>
          ) : null}
        </div>
      )}
    </>
  );
}

export default SliderManage;
