import React from 'react';
import StageAreaComponent from './main_section/StageAreaComponent';
import ControlButtonsComponent from './main_section/ControlButtonsComponent';
import { Grid } from '@material-ui/core';
import FooterParticipantList from './main_section/FooterParticipantList';

const MainSectionComponent = ({ isReconnecting }) => {
  return (
    <Grid item xs={12} md={7} lg={7} xl={9} className="content-wrap">
      {/* Stage Area Component */}
      <StageAreaComponent />
      {/* END: Stage Area Component */}

      {/* Control Buttons */}
      <ControlButtonsComponent isReconnecting={isReconnecting} />
      {/* END: Control Buttons */}

      {/* Footer/Right Participant List */}
      <FooterParticipantList />
      {/* END: Footer/Right Participant List */}
    </Grid>
  );
};

export default MainSectionComponent;
