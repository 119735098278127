import React, { useContext, useState } from 'react';
import { ParticipantDetailsContext } from '../contexts/ParticipantDetailsContext';
import { Button } from '@material-ui/core';
import { GlobalRoomContext } from '../contexts/GlobalRoomContext';
import { Modal } from 'react-bootstrap';
import { WorkshopContext } from '../contexts/WorkshopContext';
import { toast } from 'react-toastify';

export default function InviteButton() {
  const { participantDetails } = useContext(ParticipantDetailsContext);
  const { isHost } = useContext(GlobalRoomContext);
  const [open, setOpen] = useState(false);
  const { currentWorkshop } = useContext(WorkshopContext);

  const [formValues, setFormValues] = useState([{ name: '', email: '' }]);

  let handleChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  };

  let addFormFields = () => {
    setFormValues([...formValues, { name: '', email: '' }]);
  };

  let removeFormFields = i => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  // Invite Participants API call
  const InviteParticipants = () => {
    fetch(process.env.REACT_APP_API_URL + '/invite-participants', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + participantDetails?.token,
      },
      body: JSON.stringify({
        workshopId: currentWorkshop.id,
        aciveMeetingId: currentWorkshop.active_meeting.id,
        formValues,
      }),
    })
      .then(res => {
        if (!res.ok) {
          // error coming back from server
          throw Error('Something went wrong! Please, Try again later.');
        }
        return res.json();
      })
      .then(data => {
        toast.success(data.data.message, { position: 'top-center' });
      });
  };

  return (
    <>
      {isHost && (
        <Button className="btn btn-success" onClick={() => setOpen(true)} title="Invite">
          <i className="fas fa-user-plus"></i>
        </Button>
      )}

      {/* Modal for Invite */}
      <Modal show={open} onHide={() => setOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Invite Participants in Meeting!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {formValues.map((element, index) => (
            <div className="row" key={index}>
              <div className="form-group col">
                <label>Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={element.name || ''}
                  onChange={e => handleChange(index, e)}
                />
              </div>
              <div className="form-group col">
                <label>Email</label>
                <input
                  type="text"
                  className="form-control"
                  name="email"
                  value={element.email || ''}
                  onChange={e => handleChange(index, e)}
                />
              </div>
              {index ? (
                <div className="col-auto">
                  <Button
                    className="btn btn-danger"
                    style={{ position: 'relative', top: '26px' }}
                    onClick={() => removeFormFields(index)}
                  >
                    Remove
                  </Button>
                </div>
              ) : (
                <div className="col-auto">
                  <Button
                    className="btn btn-danger"
                    style={{ position: 'relative', top: '26px', visibility: 'hidden' }}
                  >
                    Remove
                  </Button>
                </div>
              )}
            </div>
          ))}
          <div>
            <Button className="btn btn-success" onClick={() => addFormFields()}>
              Add
            </Button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-danger mr-3" onClick={() => setOpen(false)}>
            Close
          </Button>
          <Button className="btn btn-success" onClick={() => InviteParticipants()}>
            Invite
          </Button>
        </Modal.Footer>
      </Modal>
      {/* END: Modal for Invite */}
    </>
  );
}
