import React, { useContext } from 'react';
import { Typography } from '@material-ui/core';
import { ParticipantDetailsContext } from '../../contexts/ParticipantDetailsContext';

export function Dashboard() {
  const { participantDetails } = useContext(ParticipantDetailsContext);

  window.onbeforeunload = event => {
    const e = event || window.event;
    // Cancel the event
    e.preventDefault();
    if (e) {
      e.returnValue = ''; // Legacy method for cross browser support
    }
    return ''; // Legacy method for cross browser support
  };

  return (
    <>
      <Typography variant="h3" component="h3" gutterBottom>
        Hello, {participantDetails?.name}
      </Typography>
    </>
  );
}
