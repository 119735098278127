import React, { useState, useEffect, useRef, useContext } from 'react';
import { GlobalRoomContext } from '../../../contexts/GlobalRoomContext';
import useMainParticipant from '../../../../hooks/useMainParticipant/useMainParticipant';
import useScreenShareParticipant from '../../../../hooks/useScreenShareParticipant/useScreenShareParticipant';
import useSelectedParticipant from "../../../../components/VideoProvider/useSelectedParticipant/useSelectedParticipant";

const Participant = ({ participant, currentWorkshop, isCreator, isLocal }) => {
  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);
  const { peermode, promotedParticipant } = useContext(GlobalRoomContext);
  const [selectedParticipant] = useSelectedParticipant();
  const mainParticipant = useMainParticipant();
  const screenShareParticipant = useScreenShareParticipant();

  // Create refs for the HTML elements to attach audio and video to in the DOM
  // For now, set them to null
  const videoRef = useRef(null);
  const audioRef = useRef(null);

  // Get the audio and video tracks from the participant, filtering out the tracks that are null
  const getExistingVideoTracks = participant => {
    const videoPublications = Array.from(participant.videoTracks.values());
    const existingVideoTracks = videoPublications.map(publication => publication.track).filter(track => track !== null);
    return existingVideoTracks;
  };

  const getExistingAudioTracks = participant => {
    const audioPublications = Array.from(participant.audioTracks.values());
    const existingAudioTracks = audioPublications.map(publication => publication.track).filter(track => track !== null);
    return existingAudioTracks;
  };

  // When a new track is added or removed, update the video and audio tracks in the state
  useEffect(() => {
    const trackSubscribed = track => {
      if (track.kind === 'video') {
        setVideoTracks(videoTracks => [...videoTracks, track]);
      } else {
        setAudioTracks(audioTracks => [...audioTracks, track]);
      }
    };

    const trackUnsubscribed = track => {
      if (track.kind === 'video') {
        setVideoTracks(videoTracks => videoTracks.filter(v => v !== track));
      } else {
        setAudioTracks(audioTracks => audioTracks.filter(a => a !== track));
      }
    };

    setVideoTracks(getExistingVideoTracks(participant));
    setAudioTracks(getExistingAudioTracks(participant));

    // Set up event listeners
    participant.on('trackSubscribed', trackSubscribed);
    participant.on('trackUnsubscribed', trackUnsubscribed);

    // Clean up at the end by removing all the tracks and the event listeners
    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
      participant.videoTracks.forEach(track => (track.isEnabled = false));
    };
  }, [participant]);

  // When a new videoTrack or audioTrack is subscribed, add it to the DOM.
  // When unsubscribed, detach it
  useEffect(() => {
    const videoTrack = videoTracks[0];
    if (videoRef && videoRef.current) {
      if (videoTrack) {
        videoTrack.attach(videoRef.current);
        return () => {
          videoTrack.detach();
        };
      }
    }
  }, [videoTracks]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioRef && audioRef.current) {
      if (audioTrack) {
        audioTrack.attach(audioRef.current);
        return () => {
          audioTrack.detach();
        };
      }
    }
  }, [audioTracks]);

  let isMuted = true;
  if (isCreator && currentWorkshop.broadcast && !isLocal) {
    isMuted = false;
  }

  // useEffect(() => {
  //   console.log('Screen', mainParticipant.identity);
  //   console.log('part', participant.identity);
  // }, [mainParticipant, participant]);

  return (
    <div
      className="ratio ratio-4x3 off-stage-author"
      id={participant.identity}
      style={{ display: isCreator ? 'block' : 'none' }}
    >
      {/* <div className="identity">
        {participant.identity} {isCreator ? ' (Author)' : ''}
        {isMuted ? ' (Mute)' : ' (Audible)'}
      </div> */}

      {/* If the author is on the main stage and not screen sharing then show the author initials over the video */}
      {((peermode && mainParticipant.identity === participant.identity) || !peermode) && !screenShareParticipant && !promotedParticipant && !selectedParticipant ? (
        <div className="off-stage-avtar">
          <span className="off-stage-author-initials">
            {participant.identity.replace('(educator)', '').match(/\b(\w)/g)}
          </span>
        </div>
      ) : null}

      <video ref={videoRef} autoPlay={true} />
      <audio ref={audioRef} muted={isMuted} />
    </div>
  );
};

export default Participant;
