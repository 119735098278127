import React, { useContext } from 'react';
import { Button } from '@material-ui/core';
import { GlobalRoomContext } from '../contexts/GlobalRoomContext';

const ReturnMainRoomButton = () => {
  const { globalRoomName, globalSubRoomName, isBreak, changeRoom, peermode, isHost } = useContext(GlobalRoomContext);

  if (!isBreak || !peermode || globalSubRoomName === globalRoomName || !isHost) {
    return null;
  }

  return (
    <Button className="btn btn-success" onClick={() => changeRoom(globalRoomName)} title="Return To Main">
      <i className="fas fa-reply"></i>
    </Button>
  );
};

export default ReturnMainRoomButton;
