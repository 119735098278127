import React, { useContext, useEffect } from 'react';
import { Button } from '@material-ui/core';

import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { ToggleMeetingContext } from '../../../app/contexts/ToggleMeetingContext';
import { GlobalRoomContext } from '../../../app/contexts/GlobalRoomContext';
import { WorkshopContext } from '../../../app/contexts/WorkshopContext';
import { toast } from 'react-toastify';
import {ParticipantDetailsContext} from "../../../app/contexts/ParticipantDetailsContext";

export default function EndCallButton(props: { className?: string }) {
  const { room } = useVideoContext();
  const { globalRoomName, hiddenRoomObject, isHost } = useContext(GlobalRoomContext);
  const { setIsMeetingStarted, removeActiveMeeting } = useContext(ToggleMeetingContext);
  const { currentWorkshop } = useContext(WorkshopContext);
  const { participantDetails } = useContext(ParticipantDetailsContext);

  const handleDisconnect = () => {
    /* Remove all the meeting related data */
    localStorage.removeItem('roomCode');
    localStorage.removeItem('chatToken');
    localStorage.removeItem('current_room');
    localStorage.removeItem('current_sub_room');
    if(!isHost) {
      localStorage.removeItem('currentMeetingWorkshop');
    }

    if (!hiddenRoomObject) {
      removeActiveMeeting(globalRoomName, room!.sid);
    } else {
      removeActiveMeeting(globalRoomName, room!.sid, hiddenRoomObject!.sid);
    }

    setIsMeetingStarted(false);

    if (hiddenRoomObject) {
      hiddenRoomObject!.disconnect();
    }

    /* If the Participant is a Guest then redirect them to the Login Page */
    if(participantDetails && participantDetails.user_type === "guest") {
      room!.disconnect();
      sessionStorage.clear();
      localStorage.clear();
      window.location.reload();
    }
    return room!.disconnect();
  };

  useEffect(() => {
    if (!isHost && currentWorkshop && currentWorkshop.active_meeting === null) {
      toast.warning('The host is ending the workshop. You will disconnect shortly.', { position: 'top-center' });

      setTimeout(() => {
        handleDisconnect();
      }, 3000);
    }
  }, [isHost, currentWorkshop]);

  return (
    <Button
      variant="outlined"
      size="small"
      className="btn btn-danger me-2"
      onClick={() => handleDisconnect()}
      data-cy-disconnect
    >
      {isHost ? 'END SESSION' : 'LEAVE SESSION'}
    </Button>
  );
}
