import React from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { Layout } from '../_metronic/layout';
import BasePage from './BasePage';
import { Logout, AuthPage } from './modules/Auth';
import ErrorsPage from './modules/ErrorsExamples/ErrorsPage';
import useParticipantDetails from './hooks/useParticipantDetails';

export function Routes() {
  const { participantDetails } = useParticipantDetails();

  return (
    <Switch>
      {!participantDetails?.token ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise, redirect to root page (`/`)*/
        <Redirect from="/login" to="/" />
      )}

      <Route path="/error" component={ErrorsPage} />
      <Route path="/logout" component={Logout} />

      {!participantDetails?.token ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/login" />
      ) : (
        <Layout>
          <BasePage />
        </Layout>
      )}
    </Switch>
  );
}
