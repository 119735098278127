export const DEFAULT_VIDEO_CONSTRAINTS: MediaStreamConstraints['video'] = {
  width: 1920, // 1920|1280|640|176
  height: 1080, // 1080|720|480|144
  frameRate: 30, // 30|24
};

export const DEFAULT_VIDEO_CONSTRAINTS_STUDENT: MediaStreamConstraints['video'] = {
  width: 640, // 1920|1280|640|176
  height: 480, // 1080|720|480|144
  frameRate: 24, // 30|24
};

// These are used to store the selected media devices in localStorage
export const SELECTED_AUDIO_INPUT_KEY = 'TwilioVideoApp-selectedAudioInput';
export const SELECTED_AUDIO_OUTPUT_KEY = 'TwilioVideoApp-selectedAudioOutput';
export const SELECTED_VIDEO_INPUT_KEY = 'TwilioVideoApp-selectedVideoInput';

// This is used to store the current background settings in localStorage
export const SELECTED_BACKGROUND_SETTINGS_KEY = 'TwilioVideoApp-selectedBackgroundSettings';
