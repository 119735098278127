import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Card, CardContent, Divider, Typography } from '@material-ui/core';
import { toast } from 'react-toastify';
import { ParticipantDetailsContext } from '../../../contexts/ParticipantDetailsContext';
import { WorkshopContext } from '../../../contexts/WorkshopContext';
import {GlobalRoomContext} from "../../../contexts/GlobalRoomContext";

function PollManage({ openPoll }) {
  const { participantDetails } = useContext(ParticipantDetailsContext);
  const { currentWorkshop, polls } = useContext(WorkshopContext);
  const { hiddenRoomParticipants } = useContext(GlobalRoomContext);

  // Poll
  const [loading, setLoading] = useState(false);
  const [statusLoading, setStatusLoading] = useState(true);
  const [pollQue, setPollQue] = useState('');
  const [pollOpt1, setPollOpt1] = useState('');
  const [pollOpt2, setPollOpt2] = useState('');
  const handlePollQueChange = useCallback(event => {
    setPollQue(event.target.value);
  }, []);
  const handlePollOpt1Change = useCallback(event => {
    setPollOpt1(event.target.value);
  }, []);
  const handlePollOpt2Change = useCallback(event => {
    setPollOpt2(event.target.value);
  }, []);

  useEffect(() => {
    setLoading(false);
    setStatusLoading(false);
  }, [polls]);

  //Create Poll
  const CreatePoll = () => {
    setLoading(true);
    fetch(process.env.REACT_APP_API_URL + '/create-poll', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + participantDetails?.token,
      },
      body: JSON.stringify({
        workshopId: currentWorkshop.id,
        pollQue,
        pollOpt1,
        pollOpt2,
      }),
    })
      .then(res => {
        if (!res.ok) {
          setLoading(false);
          toast.error('Please fill all the inputs!');
        }
        return res.json();
      })
      .then(data => {});
  };

  //Toggle Poll Status
  const togglePollStatus = (pollId, status) => {
    setStatusLoading(true);
    fetch(process.env.REACT_APP_API_URL + '/active-poll', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + participantDetails?.token,
      },
      body: JSON.stringify({
        workshopId: currentWorkshop.id,
        pollId,
        status,
      }),
    })
      .then(res => {
        if (!res.ok) {
          setStatusLoading(false);
          toast.error('Something went wrong! Please, Try again later.');
        }
        return res.json();
      })
      .then(data => {});
  };

  //Destroy Poll
  const destroyPoll = pollId => {
    setLoading(true);
    fetch(process.env.REACT_APP_API_URL + '/delete-poll', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + participantDetails?.token,
      },
      body: JSON.stringify({
        pollId,
      }),
    })
      .then(res => {
        if (!res.ok) {
          setLoading(false);
          toast.error('Something went wrong! Please, Try again later.');
        }
        return res.json();
      })
      .then(data => {});
  };

  return (
    <>
      {openPoll && (
        <div className="bg-white p-2" style={{ borderRadius: '8px' }}>
          {polls && !polls.length && (
            <>
              <div className="form-group row mt-1 mb-0 justify-content-between">
                <label className="col-form-label col-4">Poll Question</label>
                <div className="col-7 justify-content-between">
                  <input
                    type="text"
                    placeholder="Poll Question"
                    className="form-control form-control-sm"
                    value={pollQue}
                    onChange={handlePollQueChange}
                  />
                </div>
              </div>
              <div className="form-group row mt-1 mb-0 justify-content-between">
                <label className="col-form-label col-4">Option 1</label>
                <div className="col-7 justify-content-between">
                  <input
                    type="text"
                    placeholder="Poll Option 1"
                    className="form-control form-control-sm"
                    value={pollOpt1}
                    onChange={handlePollOpt1Change}
                  />
                </div>
              </div>
              <div className="form-group row mt-1 mb-0 justify-content-between">
                <label className="col-form-label col-4">Option 2</label>
                <div className="col-7 justify-content-between">
                  <input
                    type="text"
                    placeholder="Poll Option 2"
                    className="form-control form-control-sm"
                    value={pollOpt2}
                    onChange={handlePollOpt2Change}
                  />
                </div>
              </div>

              <div className="form-group row my-1 justify-content-between">
                <label className="col-4 col-form-label"></label>
                <div className="col-7 justify-content-between">
                  <Button
                    className="btn btn-success font-weight-bold px-9"
                    onClick={() => CreatePoll()}
                    disabled={loading}
                  >
                    <span>Create Poll</span>
                    {loading && <span className="ml-3 spinner spinner-white"></span>}
                  </Button>
                </div>
              </div>
            </>
          )}

          {polls && polls.length ? (
            <>
              <Divider />
              <Card style={{ backgroundColor: 'rgb(44 188 152)', boxShadow: '1px 1px 5px grey' }} className="mb-2">
                <CardContent style={{ padding: '5px' }}>
                  <div className="row">
                    <div style={{ textAlign: 'center' }} className="col-9">
                      <Typography variant="paragraph" style={{ position: 'relative', color: 'white' }}>
                        Poll / Result
                      </Typography>
                    </div>
                    <div style={{ textAlign: 'center' }} className="col-3">
                      <Typography variant="paragraph" style={{ position: 'relative', color: 'white' }}>
                        Actions
                      </Typography>
                    </div>
                  </div>
                </CardContent>
              </Card>

              {polls.map(poll => (
                <Card
                  style={{ backgroundColor: 'rgb(244 244 244)', boxShadow: '1px 1px 5px grey' }}
                  className="mb-2"
                  key={poll.id}
                >
                  <CardContent style={{ padding: '5px' }}>
                    <div className="row">
                      <div style={{ textAlign: 'center' }} className="col-9">
                        <span className="pollQuestion">{poll.title}</span>
                        <div className="pollSticker py-2">
                          {poll.options.map((option, key) => (
                            <Button className="pollOption" key={option.id}>
                              {option.text}
                              {poll.answers_results[key] >= 50 ? (
                                <span className="pollOptionResult text-success">{`${poll.answers_results[key]}%`}</span>
                              ) : (
                                <span className="pollOptionResult text-warning">{`${poll.answers_results[key]}%`}</span>
                              )}
                            </Button>
                          ))}
                        </div>
                      </div>
                      <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} className="col-3">
                        <div className="action" style={{ display: 'inherit' }}>
                          {poll.is_active ? (
                            <a className="btn btn-sm btn-clean btn-icon" onClick={() => togglePollStatus(poll.id, 0)}>
                              {statusLoading && (
                                <span className="spinner spinner-gray mr-2" style={{ fontSize: '0.7rem' }}></span>
                              )}
                              {!statusLoading && <i className="fas fa-pause text-warning" style={{ fontSize: '1rem' }} />}
                            </a>
                          ) : (
                            <a className="btn btn-sm btn-clean btn-icon" onClick={() => togglePollStatus(poll.id, 1)}>
                              {statusLoading && (
                                <span className="spinner spinner-gray mr-2" style={{ fontSize: '0.7rem' }}></span>
                              )}
                              {!statusLoading && <i className="fas fa-play text-success" style={{ fontSize: '1rem' }} />}
                            </a>
                          )}
                          <a className="btn btn-sm btn-clean btn-icon" onClick={() => destroyPoll(poll.id)}>
                            {loading && (
                              <span className="spinner spinner-gray mr-4" style={{ fontSize: '0.7rem' }}></span>
                            )}
                            {!loading && <i className="fas fa-trash text-danger" style={{ fontSize: '1rem' }} />}
                          </a>
                        </div>
                        <div className="result">
                          <span style={{ fontWeight: '600', color: '#bababa' }}>{poll.total_answers} / {hiddenRoomParticipants.length}</span>
                        </div>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              ))}
            </>
          ) : null}
        </div>
      )}
    </>
  );
}

export default PollManage;
