import React, { useContext } from 'react';
import MainParticipant from '../../../components/MainParticipant/MainParticipant';
import { CurrentActiveRoomContext } from '../../contexts/CurrentActiveRoomContext';
import TakeScreenshotButton from '../../buttons/TakeScreenshotButton';
import { GlobalRoomContext } from '../../contexts/GlobalRoomContext';

const MainParticipantVideo = () => {
  const { activeParticipantsGrid } = useContext(CurrentActiveRoomContext);
  const { isHost, isBreak } = useContext(GlobalRoomContext);

  const mainClass = isHost && !isBreak ? 'ratio ratio-16x9-isHostMainRoom' : 'ratio ratio-16x9';

  return (
    <div className={activeParticipantsGrid ? `d-none card card-with-name mb-4` : `card card-with-name mb-4`}>
      <div className="card-body p-0">
        <div className="card-name">Stage</div>
        <div className="card-video">
          <div className={mainClass}>
            <MainParticipant />
          </div>
          {/* Taking a screenshot of main stage area */}
          <TakeScreenshotButton />
          {/* END: Taking a screenshot of main stage area */}
        </div>
      </div>
    </div>
  );
};

export default MainParticipantVideo;
