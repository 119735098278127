import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { ParticipantDetailsContext } from '../../../contexts/ParticipantDetailsContext';
import { makeStyles } from '@material-ui/core/styles';
import { GlobalRoomContext } from '../../../contexts/GlobalRoomContext';
import { ToggleMeetingContext } from '../../../contexts/ToggleMeetingContext';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
}));

const OtherWorkshops = ({ workshops, copyToClip }) => {
  const classes = useStyles();

  const { participantDetails } = useContext(ParticipantDetailsContext);
  const [enrolledWorkshops, setEnrolledWorkshops] = useState([]);
  const { setGlobalRoomName } = useContext(GlobalRoomContext);
  const { setIsMeetingStarted } = useContext(ToggleMeetingContext);
  const [onlyActive, setOnlyActive] = useState(false);

  useEffect(() => {
    if(workshops) {
      if(onlyActive) {
        const activeEnrolledWorkshops = workshops.filter(function(ws) {
          if(ws.participant_id !== participantDetails.id && ws.is_started === 1) {
            return true;
          }
          return false;
        });
        setEnrolledWorkshops(activeEnrolledWorkshops);
      } else {
        setEnrolledWorkshops(workshops.filter(workshop => workshop.participant_id !== participantDetails.id));
      }
    }
  }, [onlyActive, workshops, participantDetails])

  const setRoomNameAndStartMeeting = (room_name, workshopObj) => {
    setGlobalRoomName(room_name);
    localStorage.setItem('currentMeetingWorkshop', JSON.stringify(workshopObj));
    setIsMeetingStarted(true);
  };

  return (
    <>
      {enrolledWorkshops && enrolledWorkshops.length > 0 && (
        <Paper className={classes.root} style={{marginTop: '0px'}}>
          <div className={classes.tableWrapper}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Workshop / Meeting</TableCell>
                  <TableCell>Entities</TableCell>
                  <TableCell>RoomCode (Click To Copy)</TableCell>
                  <TableCell>
                    Action
                    <Switch color="primary" defaultChecked onChange={() => setOnlyActive(!onlyActive)} title="Only Joinable" disabled={true}/>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {enrolledWorkshops.map(enrolledWS => {
                  return (
                    <TableRow key={enrolledWS.id}>
                      <TableCell component="th" scope="row" width="20%">
                        {enrolledWS.room_name}
                      </TableCell>
                      <TableCell width="50%">
                        {enrolledWS.entities &&
                          enrolledWS.entities.map(entity => (
                            <span style={{width: '160px', marginLeft: '3px', marginRight: '3px'}} key={entity.id}>
                              <span className="label font-weight-bold label-lg label-light-dark label-inline p-2">
                                {entity.name}
                              </span>
                            </span>
                          ))}
                      </TableCell>
                      <TableCell width="20%">
                        <span className="label font-weight-bold label-lg label-dark label-inline p-2 cursor-pointer" onClick={() => copyToClip(enrolledWS.room_code)}>
                          {enrolledWS.room_code}
                        </span>
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          size="small"
                          color="primary"
                          style={{ color: 'white' }}
                          onClick={() => setRoomNameAndStartMeeting(enrolledWS.room_name)}
                          disabled={
                            !enrolledWS.is_active || !enrolledWS.is_started
                          }
                        >
                          Join
                        </Button>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </div>
        </Paper>
      )}

      {enrolledWorkshops && enrolledWorkshops.length === 0 && (
        <>
          <Paper className={classes.root} style={{marginTop: '0px'}}>
            <div style={{padding: '20px'}}>
              <Typography variant="subtitle1" component="h5" gutterBottom style={{textAlign: 'center'}}>
                <>No Workshops Found</>
              </Typography>
            </div>
          </Paper>
        </>
      )}
    </>
  );
};

export default OtherWorkshops;
