import React, { createContext } from 'react';
import useState from 'react-usestateref';

export const ChatMessagesContext = createContext();

const ChatMessagesContextProvider = props => {
  const [messages, storeMessages, ref] = useState([]);

  const setMessages = (messagesObj, isNew) => {
    if (isNew) {
      storeMessages([...ref.current, messagesObj]);
    } else {
      storeMessages(messagesObj);
    }
  };

  return (
    <ChatMessagesContext.Provider value={{ messages, setMessages }}>{props.children}</ChatMessagesContext.Provider>
  );
};

export default ChatMessagesContextProvider;
