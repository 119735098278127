import { useState } from 'react';

export default function useParticipantDetails() {
  function getParticipantDetails() {
    const userDetailsString =
      localStorage.getItem('participantDetails') !== null
        ? localStorage.getItem('participantDetails')
        : sessionStorage.getItem('participantDetails');
    return JSON.parse(userDetailsString);
  }

  const [participantDetails, setParticipantDetails] = useState(getParticipantDetails());

  const saveParticipantDetails = participantDetail => {
    if (participantDetail.token) {
      if (participantDetail.role === 'educator') {
        localStorage.setItem('participantDetails', JSON.stringify(participantDetail));
      } else {
        sessionStorage.setItem('participantDetails', JSON.stringify(participantDetail));
      }
      setParticipantDetails(participantDetail);
    }
  };

  return {
    setParticipantDetails: saveParticipantDetails,
    participantDetails,
  };
}
