import React, { useState } from 'react';
import { Card, CardContent, MenuItem, Select, Typography } from '@material-ui/core';

function ParticipantTablesSelect({ participant, tables, currentWorkshop, joinedParticipants, storeParticipantsTable }) {
  const [selectedTable, setSelectedTable] = useState(participant.current_enroll_table);

  function handleParticipantTableChange(event) {
    setSelectedTable(event.target.value);
    storeParticipantsTable(participant.id, event.target.value);
  }

  const onlineStatusClass = joinedParticipants.includes(participant.name)
    ? 'fa fa-circle text-success'
    : 'fa fa-circle text-danger';

  return (
    <Card style={{ backgroundColor: 'rgb(244 244 244)', boxShadow: '1px 1px 5px grey' }} className="mb-2">
      <CardContent style={{ padding: '7px' }}>
        <div className="row">
          <div style={{ textAlign: 'center' }} className="col-6">
            <Typography variant="h6" style={{ position: 'relative', top: '2px' }}>
              <span style={{ marginRight: '5px' }}>
                <i className={onlineStatusClass} style={{ fontSize: '12px' }}></i>
              </span>
              {participant.name}
            </Typography>
          </div>
          <div style={{ textAlign: 'center' }} className="col-6">
            <Select value={selectedTable} onChange={handleParticipantTableChange}>
              {tables.map(table => (
                <MenuItem value={table.id}>{table.title}</MenuItem>
              ))}
            </Select>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

export default ParticipantTablesSelect;
