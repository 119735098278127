import React, { createContext, useContext, useReducer, useState } from 'react';
import { RecordingRules, RoomType } from '../types';
import { TwilioError } from 'twilio-video';
import { settingsReducer, initialSettings, Settings, SettingsAction } from './settings/settingsReducer';
import useActiveSinkId from './useActiveSinkId/useActiveSinkId';
import { ParticipantDetailsContext } from '../app/contexts/ParticipantDetailsContext';

export interface StateContextType {
  error: TwilioError | Error | null;

  setError(error: TwilioError | Error | null): void;

  getToken(name: string, room: string, passcode?: string): Promise<{ room_type: RoomType; token: string }>;

  signIn?(passcode?: string): Promise<void>;

  signOut?(): Promise<void>;

  isAuthReady?: boolean;
  isFetching: boolean;
  activeSinkId: string;

  setActiveSinkId(sinkId: string): void;

  settings: Settings;
  dispatchSetting: React.Dispatch<SettingsAction>;
  roomType?: RoomType;

  updateRecordingRules(room_sid: string, rules: RecordingRules): Promise<object>;
}

export const StateContext = createContext<StateContextType>(null!);

/*
  The 'react-hooks/rules-of-hooks' linting rules prevent React Hooks from being called
  inside of if() statements. This is because hooks must always be called in the same order
  every time a component is rendered. The 'react-hooks/rules-of-hooks' rule is disabled below
  because the "if (process.env.REACT_APP_SET_AUTH === 'firebase')" statements are evaluated
  at build time (not runtime). If the statement evaluates to false, then the code is not
  included in the bundle that is produced (due to tree-shaking). Thus, in this instance, it
  is ok to call hooks inside if() statements.
*/
export default function AppStateProvider(props: React.PropsWithChildren<{}>) {
  const [error, setError] = useState<TwilioError | null>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [activeSinkId, setActiveSinkId] = useActiveSinkId();
  const [settings, dispatchSetting] = useReducer(settingsReducer, initialSettings);
  // eslint-disable-next-line
  const [roomType, setRoomType] = useState<RoomType>();
  const { participantDetails } = useContext(ParticipantDetailsContext);

  let contextValue = {
    error,
    setError,
    isFetching,
    activeSinkId,
    setActiveSinkId,
    settings,
    dispatchSetting,
  } as StateContextType;

  contextValue = {
    ...contextValue,
    getToken: async (participant_identity, room_name) => {
      return fetch(process.env.REACT_APP_API_URL + '/access-token', {
        method: 'POST',
        body: JSON.stringify({
          identity: participant_identity + ' (' + participantDetails.role + ')',
          room: room_name,
          create_conversation: process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true',
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      }).then(res => res.json());
    },
    updateRecordingRules: async (room_sid, rules) => {
      const endpoint = process.env.REACT_APP_TOKEN_ENDPOINT || '/recordingrules';

      return fetch(endpoint, {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ room_sid, rules }),
        method: 'POST',
      })
        .then(async res => {
          const jsonResponse = await res.json();

          if (!res.ok) {
            const recordingError = new Error(
              jsonResponse.error?.message || 'There was an error updating recording rules'
            );
            recordingError.code = jsonResponse.error?.code;
            return Promise.reject(recordingError);
          }

          return jsonResponse;
        })
        .catch(err => setError(err));
    },
  };

  const getToken: StateContextType['getToken'] = (name, room) => {
    setIsFetching(true);
    return contextValue
      .getToken(name, room)
      .then(res => {
        setRoomType(res.room_type);
        setIsFetching(false);
        return res;
      })
      .catch(err => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  const updateRecordingRules: StateContextType['updateRecordingRules'] = (room_sid, rules) => {
    setIsFetching(true);
    return contextValue
      .updateRecordingRules(room_sid, rules)
      .then(res => {
        setIsFetching(false);
        return res;
      })
      .catch(err => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  return (
    <StateContext.Provider value={{ ...contextValue, getToken, updateRecordingRules }}>
      {props.children}
    </StateContext.Provider>
  );
}

export function useAppState() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error('useAppState must be used within the AppStateProvider');
  }
  return context;
}
