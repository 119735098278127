import React, { useContext, useState } from 'react';
import { Button } from '@material-ui/core';
import { WorkshopContext } from '../../../contexts/WorkshopContext';
import { ParticipantDetailsContext } from '../../../contexts/ParticipantDetailsContext';
import { toast } from 'react-toastify';

function ActiveQuiz(props) {
  const { activeQuiz, currentWorkshop } = useContext(WorkshopContext);
  const { participantDetails } = useContext(ParticipantDetailsContext);

  const [loading, setLoading] = useState(0);

  //Submit Quiz Answer
  const submitQuizAnswer = (quizId, optionId) => {
    setLoading(optionId);
    fetch(process.env.REACT_APP_API_URL + '/submit-quiz-answer', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + participantDetails?.token,
      },
      body: JSON.stringify({
        quizId,
        optionId,
      }),
    })
      .then(res => {
        if (!res.ok) {
          setLoading(0);
          toast.error('Something went wrong! Please, Try again later.');
        }
        return res.json();
      })
      .then(data => {});
  };

  return (
    <div className="bg-white py-2 text-center" style={{ borderRadius: '5px' }}>
      <span className="quizQuestion">{activeQuiz.title}</span>
      <div className="quizSticker">
        {activeQuiz.options.map((option, key) => (
          <>
            {currentWorkshop &&
              currentWorkshop.answered_quiz_ids &&
              currentWorkshop.answered_quiz_ids.includes(activeQuiz.id) && (
                <Button className="quizOption" key={option.id}>
                  <span className="QuizOptionInitial">
                    {key === 0 && !option.is_correct && 'A'}
                    {key === 1 && !option.is_correct && 'B'}
                    {key === 2 && !option.is_correct && 'C'}
                    {option.is_correct ? (
                      <i className="fas fa-check-circle text-success" style={{ fontSize: '1rem' }} />
                    ) : null}
                  </span>
                  {option.text}
                  <span className="QuizOptionCount">{option.total_count}</span>
                </Button>
              )}

            {currentWorkshop &&
              currentWorkshop.answered_quiz_ids &&
              !currentWorkshop.answered_quiz_ids.includes(activeQuiz.id) && (
                <Button
                  className="quizOption"
                  key={option.id}
                  onClick={() => submitQuizAnswer(activeQuiz.id, option.id)}
                  disabled={loading === option.id}
                >
                  <span className="QuizOptionInitial">
                    {key === 0 && 'A'}
                    {key === 1 && 'B'}
                    {key === 2 && 'C'}
                  </span>
                  {option.text}
                  {loading === option.id ? <span className="mx-3 spinner spinner-dark"></span> : null}
                </Button>
              )}
          </>
        ))}
      </div>
    </div>
  );
}

export default ActiveQuiz;
