import React, { useContext, useEffect, useState } from 'react';
import QuizManage from './stickers/QuizManage';
import SliderManage from './stickers/SliderManage';
import PollManage from './stickers/PollManage';
import { GlobalRoomContext } from '../../contexts/GlobalRoomContext';
import { WorkshopContext } from '../../contexts/WorkshopContext';
import { Button } from '@material-ui/core';
import ActiveQuiz from './stickers/ActiveQuiz';
import ActiveSlider from './stickers/ActiveSlider';
import ActivePoll from './stickers/ActivePoll';

const StickersComponent = ({ setStickerExpanded }) => {
  const { isHost } = useContext(GlobalRoomContext);
  const { activeQuiz, activeSlider, activePoll } = useContext(WorkshopContext);
  const [openQuiz, setOpenQuiz] = useState(false);
  const [openPoll, setOpenPoll] = useState(false);
  const [openSlider, setOpenSlider] = useState(false);

  const toggleQuiz = () => {
    if (openQuiz) {
      setOpenQuiz(false);
    } else {
      setOpenPoll(false);
      setOpenSlider(false);
      setOpenQuiz(true);
    }
  };
  const togglePoll = () => {
    if (openPoll) {
      setOpenPoll(false);
    } else {
      setOpenQuiz(false);
      setOpenSlider(false);
      setOpenPoll(true);
    }
  };
  const toggleSlider = () => {
    if (openSlider) {
      setOpenSlider(false);
    } else {
      setOpenQuiz(false);
      setOpenPoll(false);
      setOpenSlider(true);
    }
  };

  useEffect(() => {
    if (openPoll || openQuiz || openSlider) {
      setStickerExpanded(true);
    } else {
      setStickerExpanded(false);
    }
  }, [openQuiz, openPoll, openSlider]);

  return (
    <div className="card card-with-name bg-success">
      <div className="card-body stickerSection">
        <div className="card-name">Stickers</div>
        {isHost ? (
          <>
            <Button className="btn bg-white text-success" onClick={() => toggleQuiz()}>
              {openQuiz ? (
                <i className="fas fa-check-circle text-success"></i>
              ) : (
                <i className="fas fa-check-circle"></i>
              )}
              Quiz
            </Button>
            <Button className="btn bg-white text-success" onClick={() => togglePoll()}>
              {openPoll ? <i className="fas fa-poll text-success"></i> : <i className="fas fa-poll"></i>}
              Poll
            </Button>
            <Button className="btn bg-white text-success" onClick={() => toggleSlider()}>
              {openSlider ? <i className="fas fa-sliders-h text-success"></i> : <i className="fas fa-sliders-h"></i>}
              Slider
            </Button>
            <QuizManage openQuiz={openQuiz} />
            <PollManage openPoll={openPoll} />
            <SliderManage openSlider={openSlider} />
          </>
        ) : (
          <>
            {activeQuiz && <ActiveQuiz />}

            {activeSlider && <ActiveSlider />}

            {activePoll && <ActivePoll />}
          </>
        )}
      </div>
    </div>
  );
};

export default StickersComponent;
