import React, { createContext, useState } from 'react';

export const CurrentActiveRoomContext = createContext();

const CurrentActiveRoomContextProvider = props => {
  const [joinedParticipants, setJoinedParticipants] = useState([]);
  const [activeParticipantsGrid, setActiveParticipantsGrid] = useState(false);

  return (
    <CurrentActiveRoomContext.Provider
      value={{ joinedParticipants, setJoinedParticipants, activeParticipantsGrid, setActiveParticipantsGrid }}
    >
      {props.children}
    </CurrentActiveRoomContext.Provider>
  );
};

export default CurrentActiveRoomContextProvider;
