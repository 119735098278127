import React, { useContext } from 'react';
import { Button } from '@material-ui/core';
import { WorkshopContext } from '../contexts/WorkshopContext';
import { GlobalRoomContext } from '../contexts/GlobalRoomContext';

const PeerModeButton = () => {
  const { togglePeerMode, tables } = useContext(WorkshopContext);
  const { peermode, isHost } = useContext(GlobalRoomContext);
  const togglePeerModeButtonClass = peermode ? `btn btn-danger` : `btn btn-success`;
  const peerModeIconClass = `fas fa-satellite-dish`;

  if (!isHost || (tables && !tables.length)) {
    return null;
  }

  return (
    <Button className={togglePeerModeButtonClass} onClick={() => togglePeerMode()} title="Toggle Peer Mode">
      <i className={peerModeIconClass}></i>
    </Button>
  );
};

export default PeerModeButton;
