import React, {useContext, useState} from 'react';
import { Typography } from '@material-ui/core';
import { ParticipantDetailsContext } from "../contexts/ParticipantDetailsContext";
import { Link, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

async function joinRoomNow(form_data, token) {
  return fetch(process.env.REACT_APP_API_URL + '/join-room-with-login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
    body: JSON.stringify(form_data),
  })
    .then(response => {
      return response.json();
    })
    .then(result => {
      if (result.errors !== undefined) {
        return result.errors;
      }
      return result;
    });
}

export function JoinNow() {
  const search = useLocation().search;
  const { participantDetails } = useContext(ParticipantDetailsContext);

  const [email, setEmail] = useState(participantDetails?.email);
  const [userName, setUserName] = useState(participantDetails?.name);
  const [roomCode, setRoomCode] = useState(new URLSearchParams(search).get('roomCode'));
  const [loading, setLoading] = useState(false);

  const handleSubmit = async e => {
    setLoading(true);
    e.preventDefault();
    const responseData = await joinRoomNow({
      roomCode,
    }, participantDetails?.token);
    if (responseData.workshop !== undefined) {
      toast.success('Joining the Room!');

      await setRoomNameAndStartMeeting(responseData.workshop.room_name, responseData.workshop);

      setTimeout(() => {
        setLoading(false);
        window.location.href = '/';
      }, 2500);
    } else {
      setLoading(false);
      var errors = responseData;
      Object.keys(errors).map((error, index) => toast.error(errors[error][index]));
    }
  };

  const setRoomNameAndStartMeeting = async(room_name, meetingWorkshop) => {
    localStorage.setItem('roomCode', roomCode);
    localStorage.setItem('current_room', room_name);
    localStorage.setItem('currentMeetingWorkshop', JSON.stringify(meetingWorkshop));
  };

  return (
    <>
      <Typography variant="h3" component="h3" gutterBottom>
        Hello, {participantDetails?.name}
      </Typography>

      <div className="card" style={{ display: 'block', margin: '0 auto', padding: '10px 50px', width: '40%' }}>
        <div className="text-center mb-5 mb-lg-10">
          <h3 className="font-size-h1">Join Room</h3>
          <p className="text-muted font-weight-bold">Enter Room Code to join room</p>
        </div>

        {/*begin::Form*/}
        <form
          className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp text-center"
          onSubmit={handleSubmit}
        >
          {/* begin: Email */}
          <div className="form-group fv-plugins-icon-container">
            <input
              placeholder="Email"
              type="email"
              className={`form-control form-control-solid h-auto py-5 px-6`}
              value={email}
              onChange={e => setEmail(e.target.value)}
              disabled={true}
            />
          </div>
          {/* end: Email */}

          {/* begin: Username */}
          <div className="form-group fv-plugins-icon-container">
            <input
              placeholder="User Name (Optional)"
              type="text"
              className={`form-control form-control-solid h-auto py-5 px-6`}
              value={userName}
              onChange={e => setUserName(e.target.value)}
              disabled={true}
            />
          </div>
          {/* end: Username */}

          {/* begin: RoomCode */}
          <div className="form-group fv-plugins-icon-container">
            <input
              placeholder="Room Code"
              type="text"
              className={`form-control form-control-solid h-auto py-5 px-6`}
              value={roomCode}
              onChange={e => setRoomCode(e.target.value)}
            />
          </div>
          {/* end: RoomCode */}

          <div className="form-group d-flex flex-wrap justify-content-between">
            <button type="submit" className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4">
              <span>Join Now</span>
              {loading && <span className="ml-3 spinner spinner-white"></span>}
            </button>

            <Link to="/">
              <button type="button" className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4">
                Cancel
              </button>
            </Link>
          </div>
        </form>
        {/*end::Form*/}
        <ToastContainer autoClose={2500} />
      </div>
    </>
  );
}
