import React, { useState, useEffect, useMemo } from 'react';
import objectPath from 'object-path';
import { useHtmlClassService } from '../../_core/MetronicLayout';
import { Brand } from '../brand/Brand';
import useParticipantDetails from '../../../../app/hooks/useParticipantDetails';
import EducatorSidebar from './EducatorSidebar';
import StudentSidebar from './StudentSidebar';
import { Divider, Paper, Popper, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';

export function Aside() {
  const uiService = useHtmlClassService();
  const { participantDetails } = useParticipantDetails();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(prev => (prev ? null : event.currentTarget));
  };

  const open = Boolean(anchorEl);
  const id = open ? 'no-transition-popper' : undefined;

  const layoutProps = useMemo(() => {
    return {
      asideClassesFromConfig: uiService.getClasses('aside', true),
      asideSecondaryDisplay: objectPath.get(uiService.config, 'aside.secondary.display'),
      asideSelfMinimizeToggle: objectPath.get(uiService.config, 'aside.self.minimize.toggle'),
      extrasSearchDisplay: objectPath.get(uiService.config, 'extras.search.display'),
      extrasNotificationsDisplay: objectPath.get(uiService.config, 'extras.notifications.display'),
      extrasQuickActionsDisplay: objectPath.get(uiService.config, 'extras.quick-actions.display'),
      extrasQuickPanelDisplay: objectPath.get(uiService.config, 'extras.quick-panel.display'),
      extrasLanguagesDisplay: objectPath.get(uiService.config, 'extras.languages.display'),
      extrasUserDisplay: objectPath.get(uiService.config, 'extras.user.display'),
    };
  }, [uiService]);

  const removeActiveMeeting = async room_name => {
    const postData = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + participantDetails?.token,
      },
      body: JSON.stringify({ room_name: room_name }),
    };

    const fetchResponse = await fetch(process.env.REACT_APP_API_URL + '/remove-active-meeting', postData);
    const data = await fetchResponse.json();
    return data;
  };

  const Logout = async () => {
    if (localStorage.getItem('current_room') !== '' && participantDetails.role === 'educator') {
      await removeActiveMeeting(localStorage.getItem('current_room'));
    }
    sessionStorage.clear();
    localStorage.clear();
    window.location.reload();
  };

  /* For Show/Hide side menu in the meeting section conditionally */
  const [meetingRunning, setMeetingRunning] = useState(false);
  useEffect(() => {
    const intervalId = setInterval(() => {
      //assign interval to a variable to clear it.
      if (localStorage.getItem('meeting_is_running') === 'true') {
        setMeetingRunning(true);
      } else {
        setMeetingRunning(false);
      }
    }, 2000);

    return () => clearInterval(intervalId); //This is important
  }, []);

  return (
    <>
      {/* begin::Aside */}
      <div id="kt_aside" className={`aside aside-left d-flex ${layoutProps.asideClassesFromConfig}`}>
        {/* begin::Primary */}
        <div className="aside-primary d-flex flex-column align-items-center flex-row-auto">
          <Brand />
          {/* begin::Nav Wrapper */}
          <div className="aside-nav d-flex flex-column align-items-center flex-column-fluid py-5 main-links">
            {/* begin::Nav */}
            <ul className="list-unstyled flex-column" role="tablist">
              {!meetingRunning ? (
                <>
                  {participantDetails?.role === 'educator' && <EducatorSidebar />}
                  {participantDetails?.role === 'student' && <StudentSidebar />}
                </>
              ) : null}
            </ul>
            {/* end::Nav */}
          </div>
          {/* end::Nav Wrapper */}

          {/* begin::Footer */}
          <div className="aside-footer d-flex flex-column align-items-center flex-column-auto py-4 py-lg-10">
            <Button aria-describedby={id} onClick={handleClick}>
              <img src={participantDetails.participant_detail.profile} alt="user" width="50" />
            </Button>
          </div>
          <Popper id={id} open={open} anchorEl={anchorEl} placement={'right'}>
            <Paper style={{ padding: '15px', marginLeft: '20px', textAlign: 'center' }}>
              <Typography component={'span'}>
                <Typography variant="h6" gutterBottom component={'span'}>
                  {participantDetails.name}
                  <Divider />
                </Typography>

                <Button className="btn btn-danger me-2 mt-1" variant="contained" onClick={Logout}>
                  Logout
                </Button>
              </Typography>
            </Paper>
          </Popper>
          {/*end::Footer */}
        </div>
        {/* end::Primary */}
      </div>
      {/* end::Aside */}
    </>
  );
}
