import React from "react";
import {Redirect} from "react-router-dom";
import {LayoutSplashScreen} from "../../../../_metronic/layout";


const Logout = (props) => {
  const { hasAuthToken } = props;
  return (
    hasAuthToken ? <LayoutSplashScreen /> : <Redirect to="/login" />
  )
}

export default Logout;
