import React, { useContext } from 'react';
import { Button, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import moment from 'moment-timezone';
import { ParticipantDetailsContext } from '../../../contexts/ParticipantDetailsContext';
import { GlobalRoomContext } from '../../../contexts/GlobalRoomContext';
import { ToggleMeetingContext } from '../../../contexts/ToggleMeetingContext';
import TablePagination from '@material-ui/core/TablePagination';
import TableFooter from '@material-ui/core/TableFooter';
import IconButton from '@material-ui/core/IconButton';
import LastPageIcon from '@material-ui/icons/LastPage';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  paginationLinks: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  function handleFirstPageButtonClick(event) {
    onPageChange(event, 0);
  }

  function handleBackButtonClick(event) {
    onPageChange(event, page - 1);
  }

  function handleNextButtonClick(event) {
    onPageChange(event, page + 1);
  }

  function handleLastPageButtonClick(event) {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  }

  return (
    <div className={classes.paginationLinks}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="First Page">
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="Previous Page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Next Page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Last Page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const UpcomingWorkshopMeetings = ({ workshops }) => {
  const classes = useStyles();
  const { participantDetails } = useContext(ParticipantDetailsContext);
  const { setGlobalRoomName } = useContext(GlobalRoomContext);
  const { setIsMeetingStarted } = useContext(ToggleMeetingContext);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const emptyRows = workshops ? rowsPerPage - Math.min(rowsPerPage, workshops.length - page * rowsPerPage) : 0;

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
  }

  const setRoomNameAndStartMeeting = (room_name, workshopWorkshop) => {
    setGlobalRoomName(room_name);
    localStorage.setItem('currentMeetingWorkshop', JSON.stringify(workshopWorkshop));
    setIsMeetingStarted(true);
  };

  return (
    <>
      {workshops.data && workshops.data.length > 0 && (
        <Paper className={classes.root} style={{ marginTop: '0px' }}>
          <div className={classes.tableWrapper}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {workshops.data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(workshop => (
                  <TableRow key={workshop.id}>
                    <TableCell component="th" scope="row" width="20%">
                      {workshop.title}
                    </TableCell>
                    <TableCell width="70%">{workshop.description}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        style={{ color: 'white' }}
                        onClick={() => setRoomNameAndStartMeeting(workshop.room_name, workshop)}
                        disabled={!workshop.is_started}
                      >
                        Join
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 48 * emptyRows }}>
                    <TableCell colSpan={5} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    colSpan={5}
                    count={workshops.data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { 'aria-label': 'Rows per page' },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </div>
        </Paper>
      )}

      {workshops.data && workshops.data.length === 0 && (
        <>
          <Paper className={classes.root} style={{ marginTop: '0px' }}>
            <div style={{ padding: '20px' }}>
              <Typography variant="subtitle1" component="h5" gutterBottom style={{ textAlign: 'center' }}>
                <>No Upcoming Meetings</>
              </Typography>
            </div>
          </Paper>
        </>
      )}
    </>
  );
};

export default UpcomingWorkshopMeetings;
