import React, {useContext, useEffect, useState} from 'react';
import { useAppState } from '../../../../state';
import ErrorDialog from '../../../../components/ErrorDialog/ErrorDialog';
import '../../../../types';
import { ChatProvider } from '../../../../components/ChatProvider';
import { VideoProvider } from '../../../../components/VideoProvider';
import useConnectionOptions from '../../../../utils/useConnectionOptions/useConnectionOptions';
import App from '../../../../App';
import {Snackbar, Typography} from '@material-ui/core';
import { ParticipantDetailsContext } from '../../../contexts/ParticipantDetailsContext';
import { ToggleMeetingContext } from '../../../contexts/ToggleMeetingContext';
import useFetchData from '../../../hooks/useFetchData';

import MyWorkshops from './MyWorkshops';
import OtherWorkshops from './OtherWorkshops';
import TableListingLoader from '../../../widgets/TableListingLoader';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {Link} from "react-router-dom";
import {GlobalRoomContext} from "../../../contexts/GlobalRoomContext";

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

export function Index() {
  const { participantDetails } = useContext(ParticipantDetailsContext);
  const { error, setError } = useAppState();
  const connectionOptions = useConnectionOptions();
  const { setGlobalRoomName } = useContext(GlobalRoomContext);
  const { isMeetingStarted, setIsMeetingStarted } = useContext(ToggleMeetingContext);
  const { data: workshops, isLoading } = useFetchData('workshops', 'GET');
  const [open, setOpen] = useState(false);

  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  const copyToClip = async (roomCode) => {
    await navigator.clipboard.writeText(roomCode);
    setOpen(true)
  }

  /** Use Effect For the Join Now to Join the meeting directly using the RoomCode */
  useEffect(() => {
    if(localStorage.getItem('roomCode') && localStorage.getItem('current_room') && localStorage.getItem('currentMeetingWorkshop')) {
      setGlobalRoomName(localStorage.getItem('current_room'));
      setIsMeetingStarted(true);
    }
  }, []);

  return (
    <div className="educator-meetings">
      {/* When Workshop Meetings is started */}
      {isMeetingStarted && (
        <VideoProvider options={connectionOptions} onError={setError}>
          <ErrorDialog dismissError={() => setError(null)} error={error} />
          <ChatProvider>
            <App />
          </ChatProvider>
        </VideoProvider>
      )}
      {/* END: When Workshop Meetings is started */}

      {/* When Workshop Meetings is not started */}
      {!isMeetingStarted && (
        <div className="join-meeting">
          <Typography variant="h3" component="h3" gutterBottom>
            Hello, {participantDetails?.name}
          </Typography>

          <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
              <span className="font-weight-bold text-dark-50">
                Have a Room Code?
              </span>
            <Link
              to="/join-room"
              className="font-weight-bold ml-2"
            >
              Join Now!
            </Link>
          </div>

          <Paper className={classes.root}>
            <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary">
              <Tab label="My Workshops"/>
              <Tab label="Other Workshops"/>
            </Tabs>
          </Paper>

          {/* My Workshops */}
          {value === 0 && (
            <Paper>
              {workshops && !isLoading ? (
                <MyWorkshops workshops={workshops} copyToClip={copyToClip}/>
              ) : (
                <TableListingLoader message="Loading Workshops"/>
              )}
            </Paper>
          )}
          {/* END: My Workshops */}

          {/* Other Workshops */}
          {value === 1 && (
            <Paper>
              {workshops && !isLoading ? (
                <OtherWorkshops workshops={workshops} copyToClip={copyToClip}/>
              ) : (
                <TableListingLoader message="Loading Other Workshops"/>
              )}
            </Paper>
          )}
          {/* END: Other Workshops */}
        </div>
      )}
      {/* END: When Workshop Meetings is not started */}
      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        autoHideDuration={2000}
        message="Room Code Copied to Clipboard!"
      />
    </div>
  );
}
