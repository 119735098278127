import React, { useContext } from 'react';
import { Typography } from '@material-ui/core';
import { ParticipantDetailsContext } from '../../contexts/ParticipantDetailsContext';

export function Home() {
  const { participantDetails } = useContext(ParticipantDetailsContext);

  return (
    <Typography variant="h3" component="h3" gutterBottom>
      Hello, {participantDetails?.name}
    </Typography>
  );
}
