import React, { useCallback, useRef } from 'react';

import Button from '@material-ui/core/Button';
import VideoOffIcon from '../../../icons/VideoOffIcon';
import VideoOnIcon from '../../../icons/VideoOnIcon';

import useDevices from '../../../hooks/useDevices/useDevices';
import useLocalVideoToggle from '../../../hooks/useLocalVideoToggle/useLocalVideoToggle';

export default function ToggleVideoButton(props: { disabled?: boolean; className?: string }) {
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const lastClickTimeRef = useRef(0);
  const { hasVideoInputDevices } = useDevices();
  const videoButtonToggleClass = isVideoEnabled ? `btn btn-success` : `btn btn-danger`;

  const toggleVideo = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 500) {
      lastClickTimeRef.current = Date.now();
      toggleVideoEnabled();
    }
  }, [toggleVideoEnabled]);

  return (
    <Button className={videoButtonToggleClass} disabled={!hasVideoInputDevices || props.disabled} onClick={toggleVideo} title="Toggle Video">
      {isVideoEnabled ? <VideoOnIcon /> : <VideoOffIcon />}
      {!hasVideoInputDevices ? 'No Video' : ''}
      {/*{!hasVideoInputDevices ? 'No Video' : isVideoEnabled ? 'My Camera' : 'My Camera'}*/}
    </Button>
  );
}
