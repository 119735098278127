import React from 'react';
import { styled } from '@material-ui/core/styles';
import { BrowserRouter } from 'react-router-dom';
import { MaterialThemeProvider } from './_metronic/layout';
import PreJoinScreens from './components/PreJoinScreens/PreJoinScreens';
import useRoomState from './hooks/useRoomState/useRoomState';
import { VideoApp } from './app/pages/VideoApp';

const Container = styled('div')({
  display: 'grid',
  gridTemplateRows: '1fr auto',
});

export default function App() {
  const roomState = useRoomState();

  return (
    <Container>
      <BrowserRouter>
        {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
        <MaterialThemeProvider>
          {roomState === 'disconnected' ? (
            <PreJoinScreens />
          ) : (
            <VideoApp />
            // <Main>
            //   <ReconnectingNotification />
            //   <RecordingNotifications />
            //   <MobileTopMenuBar />
            //   <Room />
            //   <MenuBar />
            // </Main>
          )}
        </MaterialThemeProvider>
      </BrowserRouter>
    </Container>
  );
}
