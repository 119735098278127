import React, { useCallback, useContext, useState, useEffect } from 'react';
import { IconButton } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Send } from '@material-ui/icons';
import ChatItem from './ChatItem';
import { GlobalRoomContext } from '../../../contexts/GlobalRoomContext';
import { ParticipantDetailsContext } from '../../../contexts/ParticipantDetailsContext';
import { ChatMessagesContext } from '../../../contexts/ChatMessagesContext';
import { useAppState } from '../../../../state';
import ChatListingLoader from '../../../widgets/ChatListingLoader';
import { WorkshopContext } from '../../../contexts/WorkshopContext';
import { Nav } from 'react-bootstrap';

const Chat = require('twilio-chat');

const ChatScreen = () => {
  const { globalRoomName, globalSubRoomName, peermode } = useContext(GlobalRoomContext);
  const { participantDetails } = useContext(ParticipantDetailsContext);
  const room = globalSubRoomName ? globalSubRoomName : globalRoomName;
  const [text, setText] = useState('');
  const [channel, setChannel] = useState(null);
  const { messages, setMessages } = useContext(ChatMessagesContext);
  const [uniqueMessages, setUniqueMessages] = useState([]);
  const [studentEnrolledTableName, setStudentEnrolledTableName] = useState(null);
  const { getToken } = useAppState();
  const [isChatLoading, setIsChatLoading] = useState(false);
  const { currentWorkshop, tables, activeQuiz } = useContext(WorkshopContext);

  const chatListClass = activeQuiz ? 'chat-msj-list small-chat' : 'chat-msj-list';

  const handleTextChange = useCallback(event => {
    setText(event.target.value);
  }, []);

  useEffect(() => {
    if (currentWorkshop !== null && currentWorkshop !== undefined) {
      if (participantDetails?.role === 'student') {
        let studentEnrolledTableName = currentWorkshop.enrolled_table_name;
        setStudentEnrolledTableName(studentEnrolledTableName);
      }
    }
  }, [currentWorkshop, participantDetails]);

  const joinChannel = async channel => {
    // console.group('CHAT');
    // console.log('channel name: ', channel.uniqueName);
    // console.log('channel status: ', channel.channelState.status);
    // console.groupEnd();
    if (channel.channelState.status !== 'joined') {
      await channel.join();
    }

    setMessages([], true);
    setChannel(channel);
    setIsChatLoading(false);

    channel.on('messageAdded', handleMessageAdded);
    document.querySelector('.chat-msj-list').scrollTop = document.querySelector('.chat-msj-list').scrollHeight;
  };

  const handleMessageAdded = message => {
    setMessages(message, true);
    document.querySelector('.chat-msj-list').scrollTop = document.querySelector('.chat-msj-list').scrollHeight;
  };

  useEffect(() => {
    // This is for prevent repeating messages and uniques by the message sid
    if (messages) {
      let filterCurrenChannelMessages = messages.filter(message => {
        if (message.channel && channel) {
          return message.channel.sid === channel.sid;
        } else {
          return {};
        }
      });
      setUniqueMessages([...new Map(filterCurrenChannelMessages.map(item => [item['sid'], item])).values()]);
    }
  }, [messages]);

  const fetchData = async room => {
    setIsChatLoading(true);
    let token = '';
    try {
      token = localStorage.getItem('chatToken')
        ? localStorage.getItem('chatToken')
        : await getToken(participantDetails.name, room);
    } catch {
      console.log('Unable to get token, please reload this page');
    }

    const client = await Chat.Client.create(token);

    client.on('channelJoined', async channel => {
      // getting list of all messages since this is an existing channel
      if (channel && channel.uniqueName === room) {
        const oldMessages = await channel.getMessages();
        setMessages(oldMessages.items || [], false);
      }
    });

    try {
      const channel = await client.getChannelByUniqueName(room);
      await joinChannel(channel);
    } catch (err) {
      try {
        const channel = await client.createChannel({
          uniqueName: room,
          friendlyName: room,
        });

        await joinChannel(channel);
      } catch {
        console.log('Unable to create chat channel, please reload this page');
      }
    }
  };

  useEffect(() => {
    fetchData(room);
  }, []);

  const sendMessage = () => {
    if (text && channel) {
      setIsChatLoading(true);
      channel.sendMessage(String(text).trim());
      setText('');
      setIsChatLoading(false);
    }
  };

  const changeChatRoom = channelName => {
    fetchData(channelName);
  };

  const mainChatClass = channel && channel.uniqueName === globalRoomName ? `card-name active` : `card-name`;
  const studentMainChatClass = channel && channel.uniqueName === globalRoomName ? `card-name active` : `card-name`;
  const studentSubChatClass = channel && channel.uniqueName !== globalRoomName ? `card-name active` : `card-name`;
  const educatorBreakoutChatClass =
    participantDetails.role === 'educator' && channel && channel.uniqueName !== globalRoomName
      ? `card-name table-chat-dropdown active`
      : `card-name table-chat-dropdown`;

  return (
    <div className="card card-with-name custom-chat-card mb-0">
      <div className="card-header bg-success text-white border-0 ">
        <div className="row align-items-center">
          <div className="col chat-heading-user">
            <img
              className="rounded-circle border border-white me-2"
              src={participantDetails.participant_detail.profile}
              alt=""
              width="40"
            />
            {participantDetails?.name}
            <span className="chat-channel-name">
              <em>- {channel && channel.uniqueName && channel.uniqueName.replace(globalRoomName + ' |', '')} -</em>
            </span>
          </div>
          <div className="col col-auto chat-heading-icon text-white">
            <Link className="me-1 mr-2" to="/meetings">
              <i className="fas fa-user-plus text-white"></i>
            </Link>
            <Link className="" to="/meetings">
              <i className="fas fa-ellipsis-v text-white"></i>
            </Link>
          </div>
        </div>
      </div>
      <div className="card-body">
        {/* Educator Chat Buttons */}
        {participantDetails.role === 'educator' && (
          <div className={mainChatClass} onClick={() => changeChatRoom(globalRoomName)}>
            Chat
          </div>
        )}
        {tables && tables.length && participantDetails.role === 'educator' ? (
          <div className="select-chat-tab card-name">
            <Nav
              activeKey={channel && channel.uniqueName}
              onSelect={selectedKey => {
                if (channel && channel.uniqueName !== selectedKey) changeChatRoom(selectedKey);
              }}
            >
              {tables &&
                tables.map((table, key) => (
                  <Nav.Item key={table.id}>
                    <Nav.Link className="select-chat-nav-link" eventKey={globalRoomName + ' | ' + table.title}>
                      #{key + 1}
                    </Nav.Link>
                  </Nav.Item>
                ))}
            </Nav>
          </div>
        ) : null}
        {/* END: Educator Chat Buttons */}

        {/* Student Chat Buttons */}
        {!peermode && participantDetails.role === 'student' && <div className={mainChatClass}>Chat</div>}
        {studentEnrolledTableName && participantDetails.role === 'student' ? (
          <>
            <div className={studentMainChatClass} onClick={() => changeChatRoom(globalRoomName)}>
              Chat
            </div>
            <div
              className={studentSubChatClass}
              style={{ left: '58px' }}
              onClick={() => changeChatRoom(globalRoomName + ' | ' + studentEnrolledTableName)}
            >
              <div className="student-table-chat-button">{studentEnrolledTableName}</div>
            </div>
          </>
        ) : null}
        {/* END: Student Chat Buttons */}

        {/* Chat Messages With Loader */}
        {isChatLoading && <ChatListingLoader type="ThreeDots" message="Fetching Messages.." />}
        {!isChatLoading && (
          <ul className={chatListClass}>
            {uniqueMessages &&
              uniqueMessages.map((message, index) => (
                <ChatItem
                  key={index}
                  message={message}
                  name={participantDetails.name + ' (' + participantDetails.role + ')'}
                />
              ))}
          </ul>
        )}
        {/* END: Chat Messages With Loader */}

        <div className="type-msj">
          <input
            className="form-control"
            type="text"
            placeholder="Type your message here"
            value={text || ''}
            disabled={!channel}
            onChange={handleTextChange}
            onKeyPress={e => {
              const keyCode = e.which || e.keyCode;
              if (keyCode === 13) {
                e.preventDefault();
                sendMessage();
              }
            }}
          />
          <IconButton onClick={sendMessage} disabled={!channel} title="Send">
            <Send style={{ color: 'rgb(44 188 152)' }} />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default ChatScreen;
