import React, { useContext, useState } from 'react';
import { Slider } from '@material-ui/core';
import { toast } from 'react-toastify';
import { WorkshopContext } from '../../../contexts/WorkshopContext';
import { ParticipantDetailsContext } from '../../../contexts/ParticipantDetailsContext';

function ActiveSlider(props) {
  const { activeSlider, currentWorkshop } = useContext(WorkshopContext);
  const { participantDetails } = useContext(ParticipantDetailsContext);

  const [loading, setLoading] = useState(false);

  //Submit Slider Answer
  const submitSliderAnswer = (event, newValue) => {
    setLoading(true);
    fetch(process.env.REACT_APP_API_URL + '/submit-slider-answer', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + participantDetails?.token,
      },
      body: JSON.stringify({
        sliderId: activeSlider.id,
        answer: newValue,
      }),
    })
      .then(res => {
        if (!res.ok) {
          setLoading(false);
          toast.error('Something went wrong! Please, Try again later.');
        }
        return res.json();
      })
      .then(data => {});
  };

  return (
    <div className="bg-white py-2 text-center" style={{ borderRadius: '5px' }}>
      <span className="sliderQuestion">{activeSlider.title}</span>
      <div className="sliderSticker">
        {currentWorkshop &&
          currentWorkshop.answered_slider_ids &&
          currentWorkshop.answered_slider_ids.includes(activeSlider.id) && (
            <div className="sliderResult">
              <div className="sliderDots sliderMyAnswer" style={{ left: `${activeSlider.my_answer}%` }}></div>
              <div className="sliderDots sliderAverageAnswer" style={{ left: `${activeSlider.average}%` }}></div>
              <div className="slider-fill" style={{ width: `${activeSlider.my_answer}%` }}></div>
            </div>
          )}

        {currentWorkshop &&
          currentWorkshop.answered_slider_ids &&
          !currentWorkshop.answered_slider_ids.includes(activeSlider.id) && (
            <Slider className="sliderAnswer" onChangeCommitted={submitSliderAnswer} disabled={loading} />
          )}
      </div>
    </div>
  );
}

export default ActiveSlider;
