import React, {useContext, useEffect, useState} from 'react';
import {
  Button,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { ParticipantDetailsContext } from '../../../contexts/ParticipantDetailsContext';
import { ToggleMeetingContext } from '../../../contexts/ToggleMeetingContext';
import { GlobalRoomContext } from '../../../contexts/GlobalRoomContext';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
}));

const MyWorkshops = ({ workshops, copyToClip }) => {
  const classes = useStyles();

  const {participantDetails} = useContext(ParticipantDetailsContext);
  const [ownWorkshops, setOwnWorkshops] = useState([]);
  const {setGlobalRoomName} = useContext(GlobalRoomContext);
  const {setIsMeetingStarted, storeActiveMeeting} = useContext(ToggleMeetingContext);
  const [onlyActive, setOnlyActive] = useState(true);

  useEffect(() => {
    if(workshops) {
      if(onlyActive) {
        const activeOwnWorkshops = workshops.filter(function(ws) {
          if(ws.participant_id === participantDetails.id && ws.is_active === 1) {
            return true;
          }
          return false;
        });
        setOwnWorkshops(activeOwnWorkshops);
      } else {
        setOwnWorkshops(workshops.filter(workshop => workshop.participant_id === participantDetails.id));
      }
    }
  }, [onlyActive, workshops, participantDetails])

  const setRoomNameAndStartMeeting = (room_name, workshopObj) => {
    setGlobalRoomName(room_name);
    if (participantDetails.role === 'educator') storeActiveMeeting(room_name, workshopObj);
    setIsMeetingStarted(true);
  };

  return (
    <>
      {ownWorkshops && ownWorkshops.length > 0 && (
        <Paper className={classes.root} style={{marginTop: '0px'}}>
          <div className={classes.tableWrapper}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Workshop / Meeting</TableCell>
                  <TableCell>Entities</TableCell>
                  <TableCell>RoomCode (Click To Copy)</TableCell>
                  <TableCell>
                    Action
                    <Switch color="primary" defaultChecked onChange={() => setOnlyActive(!onlyActive)} title="Only Startable" disabled={true}/>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ownWorkshops.map(ownWorkshop => {
                  return (
                    <TableRow key={ownWorkshop.id}>
                      <TableCell component="th" scope="row" width="20%">
                        {ownWorkshop.room_name}
                      </TableCell>
                      <TableCell width="50%">
                        {ownWorkshop.entities &&
                          ownWorkshop.entities.map(entity => (
                            <span style={{width: '160px', marginLeft: '3px', marginRight: '3px'}} key={entity.id}>
                              <span className="label font-weight-bold label-lg label-light-dark label-inline p-2">
                                {entity.name}
                              </span>
                            </span>
                          ))}
                      </TableCell>
                      <TableCell width="20%">
                        <span className="label font-weight-bold label-lg label-dark label-inline p-2 cursor-pointer" onClick={() => copyToClip(ownWorkshop.room_code)}>
                          {ownWorkshop.room_code}
                        </span>
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          size="small"
                          color="primary"
                          style={{color: 'white'}}
                          onClick={() => setRoomNameAndStartMeeting(ownWorkshop.room_name, ownWorkshop)}
                          disabled={
                            !ownWorkshop.is_active
                          }
                        >
                          Start Workshop
                        </Button>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </div>
        </Paper>
      )}

      {ownWorkshops && ownWorkshops.length === 0 && (
        <>
          <Paper className={classes.root} style={{marginTop: '0px'}}>
            <div style={{padding: '20px'}}>
              <Typography variant="subtitle1" component="h5" gutterBottom style={{textAlign: 'center'}}>
                <>No Workshops Found</>
              </Typography>
            </div>
          </Paper>
        </>
      )}
    </>
  );
};

export default MyWorkshops;
