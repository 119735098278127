import React, { createContext, useContext, useEffect, useState } from 'react';
import { ParticipantDetailsContext } from './ParticipantDetailsContext';

export const ToggleMeetingContext = createContext();

const ToggleMeetingContextProvider = props => {
  const { participantDetails } = useContext(ParticipantDetailsContext);
  const [isMeetingStarted, toggleIsMeetingStarted] = useState(false);

  const setIsMeetingStarted = status => {
    toggleIsMeetingStarted(status ?? !isMeetingStarted);
  };

  useEffect(() => {
    localStorage.setItem('meeting_is_running', isMeetingStarted);
  }, [isMeetingStarted]);

  const storeActiveMeeting = (room_name, selectedWorkshop) => {
    if (participantDetails.id === selectedWorkshop.participant_id) {
      localStorage.setItem('currentMeetingWorkshop', JSON.stringify(selectedWorkshop));
      fetch(process.env.REACT_APP_API_URL + '/store-active-meeting', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + participantDetails?.token,
        },
        body: JSON.stringify({ room_name: room_name, workshop_id: selectedWorkshop.id }),
      }).then(response => {
        // console.log('response', response);
        if (!response.ok) throw Error("Couldn't load data");
        return response.json();
      });
    }
  };

  const removeActiveMeeting = (room_name, room_sid = '', hidden_room_sid = '') => {
    let workshop = JSON.parse(localStorage.getItem('currentMeetingWorkshop'));
    let workshopId = workshop ? workshop.id : null;

    fetch(process.env.REACT_APP_API_URL + '/remove-active-meeting', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + participantDetails?.token,
      },
      body: JSON.stringify({
        room_name: room_name,
        participant_id: participantDetails.id,
        room_sid,
        hidden_room_sid,
        workshopId,
      }),
    }).then(response => {
      // console.log('response', response);
      if (!response.ok) throw Error("Couldn't load data");
      return response.json();
    });
  };

  return (
    <ToggleMeetingContext.Provider
      value={{
        isMeetingStarted,
        setIsMeetingStarted,
        storeActiveMeeting,
        removeActiveMeeting,
      }}
    >
      {props.children}
    </ToggleMeetingContext.Provider>
  );
};

export default ToggleMeetingContextProvider;
