import React, { createContext, useContext } from 'react';
import { GlobalRoomContext } from './GlobalRoomContext';

export const HelperContext = createContext();
const HelperContextProvider = props => {
  const { globalRoomName } = useContext(GlobalRoomContext);

  /**
   * Screen Shot
   * =========================================================
   */
  const takeScreenshot = () => {
    let video = document.getElementById('stage-video');
    let canvas = capture(video, 1);
    let link = document.createElement('a');
    link.download = globalRoomName + '.jpg';
    link.href = canvas.toDataURL();
    link.click();
  };

  const capture = (video, scaleFactor) => {
    if (scaleFactor == null) {
      scaleFactor = 1;
    }
    let w = 1920 * scaleFactor;
    let h = 1080 * scaleFactor;
    let canvas = document.createElement('canvas');
    canvas.width = w;
    canvas.height = h;
    let ctx = canvas.getContext('2d');
    ctx.drawImage(video, 0, 0, w, h);
    return canvas;
  };
  /** END: Screen Shot */

  /**
   *  Get Key From Object
   */
  const getKeyFromObject = (array, keys_to_keep) => {
    return array.map(o =>
      keys_to_keep.reduce((acc, curr) => {
        acc = o[curr];
        return acc;
      }, {})
    );
  };

  return <HelperContext.Provider value={{ takeScreenshot, getKeyFromObject }}>{props.children}</HelperContext.Provider>;
};

export default HelperContextProvider;
