import React, {useContext, useEffect, useState} from 'react';
import App from '../../../../App';
import { useAppState } from '../../../../state';
import useFetchData from '../../../hooks/useFetchData';
import { Typography } from '@material-ui/core';
import { ToggleMeetingContext } from '../../../contexts/ToggleMeetingContext';
import { VideoProvider } from '../../../../components/VideoProvider';
import ErrorDialog from '../../../../components/ErrorDialog/ErrorDialog';
import { ChatProvider } from '../../../../components/ChatProvider';
import useConnectionOptions from '../../../../utils/useConnectionOptions/useConnectionOptions';
import { ParticipantDetailsContext } from '../../../contexts/ParticipantDetailsContext';

import TableListingLoader from '../../../widgets/TableListingLoader';
import UpcomingWorkshopMeetings from './UpcomingWorkshopMeetings';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import {Link} from "react-router-dom";
import {GlobalRoomContext} from "../../../contexts/GlobalRoomContext";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    backgroundColor: 'white',
    boxShadow: 'none',
  },
});

export function Index() {
  const { error, setError } = useAppState();
  const connectionOptions = useConnectionOptions();
  const { setGlobalRoomName } = useContext(GlobalRoomContext);
  const { isMeetingStarted, setIsMeetingStarted } = useContext(ToggleMeetingContext);
  const { participantDetails } = useContext(ParticipantDetailsContext);
  const { data: workshops, isLoading } = useFetchData('get-student-meetings', 'POST');

  const classes = useStyles();
  const [value, setValue] = useState(0);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  /* Use Effect For the Join Now to Join the meeting directly using the RoomCode */
  useEffect(() => {
    if(localStorage.getItem('roomCode') && localStorage.getItem('current_room') && localStorage.getItem('currentMeetingWorkshop')) {
      setGlobalRoomName(localStorage.getItem('current_room'));
      setIsMeetingStarted(true);
    }
  }, []);

  return (
    <div>
      {/* When Workshop Meetings is started */}
      {isMeetingStarted && (
        <VideoProvider options={connectionOptions} onError={setError}>
          <ErrorDialog dismissError={() => setError(null)} error={error} />
          <ChatProvider>
            <App />
          </ChatProvider>
        </VideoProvider>
      )}
      {/* END: When Workshop Meetings is started */}

      {/* When Workshop Meetings is not started */}
      {!isMeetingStarted && (
        <div className="meetings-list">
          <Typography variant="h3" component="h3" gutterBottom>
            Hello, {participantDetails?.name}
          </Typography>

          <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
              <span className="font-weight-bold text-dark-50">
                Have a Room Code?
              </span>
            <Link
              to="/join-room"
              className="font-weight-bold ml-2"
            >
              Join Now!
            </Link>
          </div>

          <Paper className={classes.root}>
            <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary">
              <Tab label="Upcoming WorkShop Meetings" />
            </Tabs>
          </Paper>

          {/* Upcoming Workshop Meetings List */}
          {value === 0 && (
            <Paper>
              {isLoading && !workshops && <TableListingLoader message="Loading Upcoming Workshop Meetings" />}
              {workshops && <UpcomingWorkshopMeetings workshops={workshops} />}
            </Paper>
          )}
          {/* END: Upcoming Workshop Meetings List */}
        </div>
      )}
      {/* END: When Workshop Meetings is not started */}
    </div>
  );
}
