import React from 'react';
import MainParticipantVideo from '../stage_area/MainParticipantVideo';
import StageAreaParticipantGrid from '../stage_area/StageAreaParticipantGrid';

const StageAreaComponent = () => {
  return (
    <div>
      {/* Showing Main Participant Video On Stage Area */}
      <MainParticipantVideo />
      {/* END: Showing Main Participant Video On Stage Area */}

      {/* Showing Main Area Participant Grid */}
      <StageAreaParticipantGrid />
      {/* END: Showing Main Area Participant Grid */}
    </div>
  );
};

export default StageAreaComponent;
