import React, { useContext } from 'react';
import { Grid } from '@material-ui/core';
import ParticipantList from '../../../components/ParticipantList/ParticipantList';
import { GlobalRoomContext } from '../../contexts/GlobalRoomContext';
import { CurrentActiveRoomContext } from '../../contexts/CurrentActiveRoomContext';

const StageAreaParticipantGrid = () => {
  const { isBreak } = useContext(GlobalRoomContext);
  const { activeParticipantsGrid } = useContext(CurrentActiveRoomContext);
  const participantGridClassName = activeParticipantsGrid
    ? `card card-with-name mb-4`
    : `d-none card card-with-name mb-4`;

  if (isBreak) {
    return null;
  }

  return (
    <div className={participantGridClassName}>
      <div className="card-body p-0 part-grid">
        <div className="card-name">Participants Grid</div>
        <Grid container className="participants-grid" spacing={2}>
          <Grid item xs={12}>
            <Grid container justifyContent="center" spacing={2}>
              <ParticipantList showOnlyTable={false} />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default StageAreaParticipantGrid;
