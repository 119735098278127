import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'twilio-video';
import './App.css';
import io from 'socket.io-client';
import Room from './Room';
import { useAppState } from '../../../../state';
import { GlobalRoomContext } from '../../../contexts/GlobalRoomContext';
import { ParticipantDetailsContext } from '../../../contexts/ParticipantDetailsContext';
import { ToggleMeetingContext } from '../../../contexts/ToggleMeetingContext';
import { WorkshopContext } from '../../../contexts/WorkshopContext';

const HiddenRoom = () => {
  // State variables
  const [hiddenRoom, setHiddenRoom] = useState();
  const [socket, setSocket] = useState(null);
  const { getToken } = useAppState();
  const { globalRoomName, setHiddenRoomObject } = useContext(GlobalRoomContext);
  const { participantDetails } = useContext(ParticipantDetailsContext);
  const { isMeetingStarted } = useContext(ToggleMeetingContext);
  const { currentWorkshop } = useContext(WorkshopContext);

  useEffect(() => {
    if (process.env.REACT_APP_SOCKET_URL) {
      const socket = io(process.env.REACT_APP_SOCKET_URL, { transports: ['websocket'] });

      // Listen for events
      // socket.on('Main hiddenRoom created', () => listRooms());

      setSocket(socket);
    }

    // listRooms();

    // Clean up the listeners when the component is about to unmount.
    return () => {
      if (socket) {
        // socket.off('Main hiddenRoom created');
      }
    };
  }, []);

  useEffect(() => {
    joinHiddenRoom();
  }, []);

  useEffect(() => {
    if (!isMeetingStarted) {
      leaveHiddenRoom();
    }
  }, [isMeetingStarted]);

  const joinHiddenRoom = async () => {
    let roomName = globalRoomName + ' || hidden';

    // let roomName = 'Hidden || test';
    await getToken(participantDetails.name, roomName).then(({ token }) => {
      joinNow(token);
    });
  };

  const joinNow = async token => {
    const videoRoom = await connect(token.toString(), {
      audio: true,
      video: { width: 640, height: 480 },
    });

    setHiddenRoom(videoRoom);
    setHiddenRoomObject(videoRoom);
  };

  // Leave a video hiddenRoom
  const leaveHiddenRoom = async () => {
    if (hiddenRoom) {
      // Detach and remove all the tracks
      hiddenRoom.localParticipant.tracks.forEach(publication => {
        if (publication.track.kind === 'audio' || publication.track.kind === 'video') {
          publication.track.stop();
          const attachedElements = publication.track.detach();
          attachedElements.forEach(element => element.remove());
        }
      });

      hiddenRoom.disconnect();
      setHiddenRoom(undefined);
    }
  };

  return (
    <div className="hidden-room">
      {hiddenRoom !== undefined && <Room hiddenRoom={hiddenRoom} currentWorkshop={currentWorkshop} />}
    </div>
  );
};

export default HiddenRoom;
