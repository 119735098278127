import React, { useContext } from 'react';
import HiddenRoom from './hidden_room/HiddenRoom';
import { GlobalRoomContext } from '../../contexts/GlobalRoomContext';

const OffStageComponent = ({ stickerExpanded }) => {
  const { hiddenRoomObject, broadcast } = useContext(GlobalRoomContext);

  const mainClassName = stickerExpanded ? 'card card-with-name d-none' : 'card card-with-name';

  return (
    <div className={mainClassName}>
      <div className="card-body p-0">
        <div className="card-name">Off-stage</div>
        {broadcast ? (
          <div className="card-name broadcast">
            <i className="fas fa-bullhorn text-white mr-2"></i>
            Broadcasting..
          </div>
        ) : null}
        <div className="off-stage-section">{hiddenRoomObject !== undefined && <HiddenRoom />}</div>
      </div>
    </div>
  );
};

export default OffStageComponent;
