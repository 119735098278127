import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Card, CardContent, Divider, Typography } from '@material-ui/core';
import { toast } from 'react-toastify';
import { ParticipantDetailsContext } from '../../../contexts/ParticipantDetailsContext';
import { WorkshopContext } from '../../../contexts/WorkshopContext';
import {GlobalRoomContext} from "../../../contexts/GlobalRoomContext";

function QuizManage({ openQuiz }) {
  const { participantDetails } = useContext(ParticipantDetailsContext);
  const { currentWorkshop, quizzes } = useContext(WorkshopContext);
  const { hiddenRoomParticipants } = useContext(GlobalRoomContext);

  // QUIZ
  const [loading, setLoading] = useState(false);
  const [statusLoading, setStatusLoading] = useState(true);
  const [quizQue, setQuizQue] = useState('');
  const [quizOpt1, setQuizOpt1] = useState('');
  const [quizOpt2, setQuizOpt2] = useState('');
  const [quizOpt3, setQuizOpt3] = useState('');
  const [correctOption, setCorrectOption] = useState(1);
  const handleQuizQueChange = useCallback(event => {
    setQuizQue(event.target.value);
  }, []);
  const handleQuizOpt1Change = useCallback(event => {
    setQuizOpt1(event.target.value);
  }, []);
  const handleQuizOpt2Change = useCallback(event => {
    setQuizOpt2(event.target.value);
  }, []);
  const handleQuizOpt3Change = useCallback(event => {
    setQuizOpt3(event.target.value);
  }, []);
  const handleCorrectOptionChange = useCallback(event => {
    setCorrectOption(event.target.value);
  }, []);

  useEffect(() => {
    setLoading(false);
    setStatusLoading(false);
  }, [quizzes]);

  //Create Quiz
  const CreateQuiz = () => {
    setLoading(true);
    fetch(process.env.REACT_APP_API_URL + '/create-quiz', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + participantDetails?.token,
      },
      body: JSON.stringify({
        workshopId: currentWorkshop.id,
        quizQue,
        quizOpt1,
        quizOpt2,
        quizOpt3,
        correctOption,
      }),
    })
      .then(res => {
        if (!res.ok) {
          setLoading(false);
          toast.error('Please fill all the inputs!');
        }
        return res.json();
      })
      .then(data => {});
  };

  //Toggle Quiz Status
  const toggleQuizStatus = (quizId, status) => {
    setStatusLoading(true);
    fetch(process.env.REACT_APP_API_URL + '/active-quiz', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + participantDetails?.token,
      },
      body: JSON.stringify({
        workshopId: currentWorkshop.id,
        quizId,
        status,
      }),
    })
      .then(res => {
        if (!res.ok) {
          setStatusLoading(false);
          toast.error('Something went wrong! Please, Try again later.');
        }
        return res.json();
      })
      .then(data => {});
  };

  //Destroy Quiz
  const destroyQuiz = quizId => {
    setLoading(true);
    fetch(process.env.REACT_APP_API_URL + '/delete-quiz', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + participantDetails?.token,
      },
      body: JSON.stringify({
        quizId,
      }),
    })
      .then(res => {
        if (!res.ok) {
          setLoading(false);
          toast.error('Something went wrong! Please, Try again later.');
        }
        return res.json();
      })
      .then(data => {});
  };

  return (
    <>
      {openQuiz && (
        <div className="bg-white p-2" style={{ borderRadius: '8px' }}>
          {quizzes && !quizzes.length && (
            <>
              <div className="form-group row mt-1 mb-0 justify-content-between">
                <label className="col-form-label col-4">Quiz Question</label>
                <div className="col-7 justify-content-between">
                  <input
                    type="text"
                    placeholder="Quiz Question"
                    className="form-control form-control-sm"
                    value={quizQue}
                    onChange={handleQuizQueChange}
                  />
                </div>
              </div>
              <div className="form-group row mt-1 mb-0 justify-content-between">
                <label className="col-form-label col-4">Option 1</label>
                <div className="col-7 justify-content-between">
                  <input
                    type="text"
                    placeholder="Quiz Option 1"
                    className="form-control form-control-sm"
                    value={quizOpt1}
                    onChange={handleQuizOpt1Change}
                  />
                </div>
              </div>
              <div className="form-group row mt-1 mb-0 justify-content-between">
                <label className="col-form-label col-4">Option 2</label>
                <div className="col-7 justify-content-between">
                  <input
                    type="text"
                    placeholder="Quiz Option 2"
                    className="form-control form-control-sm"
                    value={quizOpt2}
                    onChange={handleQuizOpt2Change}
                  />
                </div>
              </div>
              <div className="form-group row mt-1 mb-0 justify-content-between">
                <label className="col-form-label col-4">Option 3</label>
                <div className="col-7 justify-content-between">
                  <input
                    type="text"
                    placeholder="Quiz Option 3"
                    className="form-control form-control-sm"
                    value={quizOpt3}
                    onChange={handleQuizOpt3Change}
                  />
                </div>
              </div>

              <div className="form-group row mt-1 mb-0 justify-content-between">
                <label className="col-4 col-form-label">Correct Option</label>
                <div className="col-7 justify-content-between">
                  <select className="custom-select" value={correctOption} onChange={handleCorrectOptionChange}>
                    <option value="1"> Option 1</option>
                    <option value="2">Option 2</option>
                    <option value="3">Option 3</option>
                  </select>
                </div>
              </div>

              <div className="form-group row my-1 justify-content-between">
                <label className="col-4 col-form-label"></label>
                <div className="col-7 justify-content-between">
                  <Button
                    className="btn btn-success font-weight-bold px-9"
                    onClick={() => CreateQuiz()}
                    disabled={loading}
                  >
                    <span>Create Quiz</span>
                    {loading && <span className="ml-3 spinner spinner-white"></span>}
                  </Button>
                </div>
              </div>
            </>
          )}

          {quizzes && quizzes.length ? (
            <>
              <Divider />
              <Card style={{ backgroundColor: 'rgb(44 188 152)', boxShadow: '1px 1px 5px grey' }} className="mb-2">
                <CardContent style={{ padding: '5px' }}>
                  <div className="row">
                    <div style={{ textAlign: 'center' }} className="col-9">
                      <Typography variant="paragraph" style={{ position: 'relative', color: 'white' }}>
                        Quiz / Result
                      </Typography>
                    </div>
                    <div style={{ textAlign: 'center' }} className="col-3">
                      <Typography variant="paragraph" style={{ position: 'relative', color: 'white' }}>
                        Actions
                      </Typography>
                    </div>
                  </div>
                </CardContent>
              </Card>

              {quizzes.map(quiz => (
                <Card
                  style={{ backgroundColor: 'rgb(244 244 244)', boxShadow: '1px 1px 5px grey' }}
                  className="mb-2"
                  key={quiz.id}
                >
                  <CardContent style={{ padding: '5px' }}>
                    <div className="row">
                      <div style={{ textAlign: 'center' }} className="col-9">
                        <span className="quizQuestion">{quiz.title}</span>
                        <div className="quizSticker">
                          {quiz.options.map((option, key) => (
                            <>
                              <Button className="quizOption" key={option.id}>
                                <span className="QuizOptionInitial">
                                  {key === 0 && !option.is_correct && 'A'}
                                  {key === 1 && !option.is_correct && 'B'}
                                  {key === 2 && !option.is_correct && 'C'}
                                  {option.is_correct ? (
                                    <i className="fas fa-check-circle text-success" style={{ fontSize: '1rem' }} />
                                  ) : null}
                                </span>
                                {option.text}
                                <span className="QuizOptionCount">{option.total_count}</span>
                              </Button>
                            </>
                          ))}
                        </div>
                      </div>

                      <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} className="col-3">
                        <div className="action" style={{ display: 'inherit' }}>
                          {quiz.is_active ? (
                            <a className="btn btn-sm btn-clean btn-icon" onClick={() => toggleQuizStatus(quiz.id, 0)}>
                              {statusLoading && (
                                <span className="spinner spinner-gray mr-2" style={{ fontSize: '0.7rem' }}></span>
                              )}
                              {!statusLoading && <i className="fas fa-pause text-warning" style={{ fontSize: '1rem' }} />}
                            </a>
                          ) : (
                            <a className="btn btn-sm btn-clean btn-icon" onClick={() => toggleQuizStatus(quiz.id, 1)}>
                              {statusLoading && (
                                <span className="spinner spinner-gray mr-2" style={{ fontSize: '0.7rem' }}></span>
                              )}
                              {!statusLoading && <i className="fas fa-play text-success" style={{ fontSize: '1rem' }} />}
                            </a>
                          )}
                          <a className="btn btn-sm btn-clean btn-icon" onClick={() => destroyQuiz(quiz.id)}>
                            {loading && (
                              <span className="spinner spinner-gray mr-4" style={{ fontSize: '0.7rem' }}></span>
                            )}
                            {!loading && <i className="fas fa-trash text-danger" style={{ fontSize: '1rem' }} />}
                          </a>
                        </div>
                        <div className="result">
                          <span style={{ fontWeight: '600', color: '#bababa' }}>{quiz.total_answers} / {hiddenRoomParticipants.length}</span>
                        </div>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              ))}
            </>
          ) : null}
        </div>
      )}
    </>
  );
}

export default QuizManage;
