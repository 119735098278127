import React from 'react';
import ToggleVideoButton from '../../../components/Buttons/ToggleVideoButton/ToggleVideoButton';
import DeviceSettingsButton from '../../buttons/DeviceSettingsButton';
import ToggleAudioButton from '../../../components/Buttons/ToggleAudioButton/ToggleAudioButton';
import MuteAllButton from '../../buttons/MuteAllButton';
// import WhiteboardButton from '../../buttons/WhiteboardButton';
import ScreenShareButton from '../../buttons/ScreenShareButton';
import TableRandomizationButton from '../../buttons/TableRandomizationButton';
import ReturnMainRoomButton from '../../buttons/ReturnMainRoomButton';
import JoinTableDropdownButton from '../../buttons/JoinTableDropdownButton';
import BroadcastButton from '../../buttons/BroadcastButton';
import PeerModeButton from '../../buttons/PeerModeButton';
import ParticipantGridButton from '../../buttons/ParticipantGridButton';
import InviteButton from '../../buttons/InviteButton';

const ControlButtonsComponent = ({ isReconnecting }) => {
  return (
    <div className="row d-flex justify-content-center align-items-baseline" style={{ gap: '4px' }}>
      {/* Toggle Video Button */}
      <ToggleVideoButton disabled={isReconnecting} />
      {/* END: Toggle Video Button */}
      {/* Doc Camera Button */}
      <DeviceSettingsButton />
      {/* END: Doc Camera Button */}
      {/* Toggle Audio Button */}
      <ToggleAudioButton disabled={isReconnecting} />
      {/* END: Toggle Audio Button */}
      {/* Mute All Button */}
      <MuteAllButton />
      {/* END: Mute All Button */}
      {/* Whiteboard Button */}
      {/*<WhiteboardButton />*/}
      {/* END: Whiteboard Button */}
      {/* Screen Share Button */}
      <ScreenShareButton />
      {/* END: Screen Share Button */}
      {/* Table Randomization Button */}
      <TableRandomizationButton />
      {/* END: Table Randomization Button */}
      {/* Return To Main Room */}
      <ReturnMainRoomButton />
      {/* END: Return To Main Room */}
      {/* Join Room Button */}
      <JoinTableDropdownButton />
      {/* END: Join Room Button */}
      {/* Broadcast Button */}
      <BroadcastButton />
      {/* END: Broadcast Button */}
      {/* Peer Mode Button */}
      <PeerModeButton />
      {/* END: Peer Mode Button */}
      {/* Participant Grid Button */}
      <ParticipantGridButton />
      {/* END: Participant Grid Button */}
      {/* Invite Button */}
      <InviteButton />
      {/* END: Invite Button */}
    </div>
  );
};

export default ControlButtonsComponent;
