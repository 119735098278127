import React, { useContext } from 'react';
import { Nav } from 'react-bootstrap';
import { Grid } from '@material-ui/core';
import ParticipantList from '../../../components/ParticipantList/ParticipantList';
import { WorkshopContext } from '../../contexts/WorkshopContext';
import { GlobalRoomContext } from '../../contexts/GlobalRoomContext';
import { CurrentActiveRoomContext } from '../../contexts/CurrentActiveRoomContext';

const FooterParticipantList = () => {
  const { tables, currentWorkshop } = useContext(WorkshopContext);
  const { globalRoomName, globalSubRoomName, isBreak, changeRoom, peermode, isHost } = useContext(GlobalRoomContext);
  const { joinedParticipants } = useContext(CurrentActiveRoomContext);

  if (!isBreak && isHost) {
    return null;
  }

  return (
    <div>
      {/* Showing Grid To Author In Breakout Room */}
      {isBreak && tables && tables.length && currentWorkshop && isHost ? (
        <div className="card card-with-name mb-4 main-screen-grid">
          <div className="card-body p-0 part-grid">
            <div className="select-table-tab card-name">
              <Nav
                activeKey={globalSubRoomName}
                onSelect={selectedKey => {
                  if (globalSubRoomName !== selectedKey) changeRoom(selectedKey);
                }}
              >
                {tables &&
                  tables.map(table => (
                    <Nav.Item key={table.id}>
                      <Nav.Link className="select-table-nav-link" eventKey={globalRoomName + ' | ' + table.title}>
                        {table.title}
                      </Nav.Link>
                    </Nav.Item>
                  ))}
              </Nav>
            </div>
            <Grid container className="participants-grid" spacing={2}>
              <Grid item xs={12}>
                <Grid container justify="center" spacing={2} className="grid-design">
                  <ParticipantList />
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      ) : null}
      {/* END: Showing Grid To Author In Breakout Room */}

      {/* Showing Grid In Breakout Room except to Author */}
      {isBreak && globalSubRoomName && currentWorkshop && peermode && !isHost ? (
        <div className="card card-with-name mb-4 main-screen-grid">
          <div className="card-body p-0 part-grid">
            <div className="card-name">My Group</div>
            <Grid container className="participants-grid" spacing={2}>
              <Grid item xs={12}>
                <Grid container justify="center" spacing={2} className="grid-design">
                  <ParticipantList showOnlyTable={false} />
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      ) : null}
      {/* END: Showing Grid In Breakout Room except to Author */}

      {/* Showing Grid On Main Room With Only Same Table Participants except to Author */}
      {currentWorkshop && !peermode && !isBreak && joinedParticipants.length ? (
        <div className="card card-with-name mb-4 main-screen-grid">
          <div className="card-body p-0 part-grid">
            <div className="card-name">{currentWorkshop.enrolled_table_name ? currentWorkshop.enrolled_table_name : '-'}</div>
            <Grid container className="participants-grid" spacing={2}>
              <Grid item xs={12}>
                <Grid container justify="center" spacing={2} className="grid-design">
                  <ParticipantList
                    sameTableParticipants={currentWorkshop.enrolled_table_participants}
                    showOnlyTable={true}
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      ) : null}
      {/* END: Showing Grid On Main Screen With Only Same Table Participants except to Author */}
    </div>
  );
};

export default FooterParticipantList;
