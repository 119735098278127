import React from 'react';
/*import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '../../../_helpers';*/

const EducatorSidebar = () => {
  return (
    <>
      {/*begin::Item*/}
      {/*<li
        className="nav-item mb-3"
        data-toggle="tooltip"
        data-placement="right"
        data-container="body"
        data-boundary="window"
        title="Dashboard"
      >
        <OverlayTrigger placement="right" overlay={<Tooltip id="metronic-features">Dashboard</Tooltip>}>
          <NavLink className="nav-link btn btn-icon btn-clean btn-lg" to="/dashboard">
            <span className="svg-icon svg-icon-lg">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Layers.svg')} />
            </span>
          </NavLink>
        </OverlayTrigger>
      </li>*/}
      {/*end::Item*/}

      {/* begin::Item */}
      {/*<li
        className="nav-item mb-3"
        data-toggle="tooltip"
        data-placement="rigth"
        data-container="body"
        data-boundary="window"
        title="Design"
      >
        <OverlayTrigger placement="right" overlay={<Tooltip id="metronic-features">Meetings</Tooltip>}>
          <NavLink className="nav-link btn btn-icon btn-clean btn-lg" to="/manage-meetings">
            <span className="svg-icon svg-icon-lg">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Sketch.svg')} />
            </span>
          </NavLink>
        </OverlayTrigger>
      </li>*/}
      {/* end::Item */}
    </>
  );
};

export default EducatorSidebar;
