import React, { useContext } from 'react';
import { Button } from '@material-ui/core';
import { ParticipantDetailsContext } from '../contexts/ParticipantDetailsContext';

const RecordSessionButton = () => {
  const { participantDetails } = useContext(ParticipantDetailsContext);

  if (participantDetails?.role !== 'educator') {
    return null;
  }

  return (
    <>
      <Button variant="outlined" size="small" color="primary" className="btn btn-outline-success text-success me-2">
        <i className="mr-2 far fa-dot-circle"></i>
        Record session
      </Button>
    </>
  );
};

export default RecordSessionButton;
