import React, { useState } from 'react';
import OffStageComponent from './sidebar/OffStageComponent';
import StickersComponent from './sidebar/StickersComponent';
import ChatScreen from './sidebar/chat/ChatScreen';
import { Grid } from '@material-ui/core';

const SidebarComponent = () => {
  const [stickerExpanded, setStickerExpanded] = useState(false);

  return (
    <Grid item xs={12} md={5} lg={5} xl={3} className="sidebar">
      {/* Off Stage */}
      <OffStageComponent stickerExpanded={stickerExpanded} />
      {/* END: Off Stage */}

      {/* Stickers */}
      <StickersComponent setStickerExpanded={setStickerExpanded} />
      {/* END: Stickers */}

      {/* Chat */}
      <ChatScreen />
      {/* END: Chat */}
    </Grid>
  );
};

export default SidebarComponent;
