import React, {useContext, useEffect, useState} from 'react';
import Participant from './Participant';
import {GlobalRoomContext} from "../../../contexts/GlobalRoomContext";

const Room = ({ hiddenRoom, currentWorkshop }) => {
  const [remoteParticipants, setRemoteParticipants] = useState(Array.from(hiddenRoom.participants.values()));
  const {setHiddenRoomParticipants} = useContext(GlobalRoomContext);

  // Whenever the hiddenRoom changes, set up listeners
  useEffect(() => {
    hiddenRoom.on('participantConnected', participant => {
      setRemoteParticipants(prevState => [...prevState, participant]);
    });
    hiddenRoom.on('participantDisconnected', participant => {
      setRemoteParticipants(prevState => prevState.filter(p => p.identity !== participant.identity));
    });
  }, [hiddenRoom]);

  // Set remote participants in the context for get the length in the stickers section
  useEffect(() => {
    setHiddenRoomParticipants(remoteParticipants);
  }, [remoteParticipants]);

  return (
    // <div className="room">
    //   <h2 className="roomName">{hiddenRoom.name}</h2>
    <div className="hidden-room-participants">
      {currentWorkshop ? (
        <>
          <Participant
            key={hiddenRoom.localParticipant.identity}
            participant={hiddenRoom.localParticipant}
            currentWorkshop={currentWorkshop}
            isCreator={hiddenRoom.localParticipant.identity.includes(currentWorkshop.created_by)}
            isLocal={true}
          />
          {remoteParticipants.map(participant => (
            <Participant
              key={participant.identity}
              participant={participant}
              currentWorkshop={currentWorkshop}
              isCreator={participant.identity.includes(currentWorkshop.created_by)}
              isLocal={false}
            />
          ))}
        </>
      ) : null}
    </div>
    // </div>
  );
};

export default Room;
