import { useEffect, useState, useContext } from 'react';
import { toast } from 'react-toastify';
import { GlobalRoomContext } from '../contexts/GlobalRoomContext';
import { WorkshopContext } from '../contexts/WorkshopContext';
import useParticipantDetails from './useParticipantDetails';

export default function useCurrentMeetWorkshopData() {
  const { participantDetails } = useParticipantDetails();
  const currentMeetingWorkshop = JSON.parse(localStorage.getItem('currentMeetingWorkshop'));
  const workshopId = currentMeetingWorkshop.id;
  const { setPeermode, setBroadcast, setIsHost } = useContext(GlobalRoomContext);
  const { setTables, setQuizzes, setActiveQuiz, setSliders, setActiveSlider, setPolls, setActivePoll } = useContext(
    WorkshopContext
  );

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    let isMounted = true;
    async function fetchData() {
      if (isMounted) {
        // console.log('Fetching data');
        fetch(process.env.REACT_APP_API_URL + '/current-meeting-workshop', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + participantDetails?.token,
          },
          body: JSON.stringify({ workshopId }),
        })
          .then(response => {
            if (!response.ok)
              throw Error("Couldn't load data from " + process.env.REACT_APP_API_URL + '/current-meeting-workshop');
            return response.json();
          })
          .then(result => {
            setError(null);
            setData(result.data);
            if (result.data) {
              setPeermode(result.data.peer_mode);
              setBroadcast(result.data.broadcast);

              if (participantDetails?.id === result.data.participant_id) {
                setIsHost(true);

                if (result.data.quizzes) {
                  setQuizzes(result.data.quizzes);
                }
                if (result.data.sliders) {
                  setSliders(result.data.sliders);
                }
                if (result.data.polls) {
                  setPolls(result.data.polls);
                }
              } else {
                setActiveQuiz(result.data.active_quiz);
                setActiveSlider(result.data.active_slider);
                setActivePoll(result.data.active_poll);
              }

              if (result.data.tables && participantDetails?.role === 'educator') {
                setTables(result.data.tables);
              }
            }
          })
          .catch(error => {
            setData(null);
            setError(error.message);
            toast.error(error.message);
          });
      }
    }
    fetchData(); // for the first render
    const intervalId = setInterval(() => {
      fetchData();
    }, 4000);
    return () => {
      clearInterval(intervalId);
      isMounted = false; // For prevent after the component did unmount
    };
  }, [participantDetails, workshopId]);

  return { data, error, workshopId };
}
