import React, { useContext, useState } from 'react';
import { Button } from '@material-ui/core';
import { GlobalRoomContext } from '../contexts/GlobalRoomContext';

const MuteAllButton = () => {
  const { isBreak, roomObject, isHost, peermode } = useContext(GlobalRoomContext);
  const [muteAll, setMuteAll] = useState(false);

  const muteAllToggle = () => {
    // Get the LocalDataTrack that we published to the room.
    const [localDataTrackPublication] = [...roomObject.localParticipant.dataTracks.values()];

    if (!muteAll) {
      setMuteAll(true);
      localDataTrackPublication.track.send('muteAll');
    } else {
      setMuteAll(false);
      localDataTrackPublication.track.send('unmuteAll');
    }
  };

  const toggleMuteAllButtonClass = muteAll ? `btn btn-danger` : `btn btn-success`;

  if (isBreak || !isHost || peermode) {
    return null;
  }

  return (
    <Button className={toggleMuteAllButtonClass} onClick={() => muteAllToggle()} title="Mute All">
      <i className="fas fa-volume-mute"></i>
    </Button>
  );
};

export default MuteAllButton;
