import React, { useContext } from 'react';
import { Button } from '@material-ui/core';
import { GlobalRoomContext } from '../contexts/GlobalRoomContext';
import { CurrentActiveRoomContext } from '../contexts/CurrentActiveRoomContext';
import { ParticipantDetailsContext } from '../contexts/ParticipantDetailsContext';

const ParticipantGridButton = () => {
  const { joinedParticipants, activeParticipantsGrid, setActiveParticipantsGrid } = useContext(
    CurrentActiveRoomContext
  );
  const { globalSubRoomName, isBreak } = useContext(GlobalRoomContext);
  const participantGridButtonClass = activeParticipantsGrid ? `btn btn-danger` : `btn btn-success`;
  const buttonText = activeParticipantsGrid ? `Close Grid` : `Participant Grid`;
  const buttonIconClass = activeParticipantsGrid ? `far fa-times-circle` : `fas fa-th-large`;
  const { participantDetails } = useContext(ParticipantDetailsContext);

  if (isBreak || globalSubRoomName || participantDetails?.role !== 'educator') {
    return null;
  }

  return (
    <Button
      className={participantGridButtonClass}
      onClick={() => setActiveParticipantsGrid(!activeParticipantsGrid)}
      disabled={joinedParticipants.length ? false : true}
      title={buttonText}
    >
      <i className={buttonIconClass}></i>
    </Button>
  );
};

export default ParticipantGridButton;
