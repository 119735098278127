import React, {useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {toast, ToastContainer} from "react-toastify";
import useParticipantDetails from "../../../hooks/useParticipantDetails";

async function joinRoomNow(form_data) {
  return fetch(process.env.REACT_APP_API_URL + '/join-room', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(form_data),
  })
    .then(response => {
      return response.json();
    })
    .then(result => {
      if (result.errors !== undefined) {
        return result.errors;
      }
      return result;
    });
}

function JoinRoom(props) {
  const search = useLocation().search;

  const { setParticipantDetails } = useParticipantDetails();

  const [email, setEmail] = useState(new URLSearchParams(search).get('email'));
  const [userName, setUserName] = useState();
  const [roomCode, setRoomCode] = useState(new URLSearchParams(search).get('roomCode'));
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState();
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async e => {
    setLoading(true);
    e.preventDefault();
    const responseData = await joinRoomNow({
      email,
      userName,
      roomCode,
      password
    });
    if (responseData.participant !== undefined && responseData.workshop !== undefined && responseData.participant.token !== undefined && responseData.alreadyExist === false) {
      toast.success('Joining the Room!');
      setParticipantDetails(responseData.participant);

      await setRoomNameAndStartMeeting(responseData.workshop.room_name, responseData.workshop);

      setTimeout(() => {
        setLoading(false);
        window.location.href = '/';
      }, 2500);
    } else if(responseData.alreadyExist === true) {
      toast.warning('Please Enter Password to Join!');
      setLoading(false);
      setShowPassword(true);
    } else {
      setLoading(false);
      var errors = responseData;
      Object.keys(errors).map((error, index) => toast.error(errors[error][index]));
    }
  };

  const setRoomNameAndStartMeeting = async(room_name, meetingWorkshop) => {
    localStorage.setItem('roomCode', roomCode);
    localStorage.setItem('current_room', room_name);
    localStorage.setItem('currentMeetingWorkshop', JSON.stringify(meetingWorkshop));
  };

  return (
    <div className="login-form login-signin" style={{ display: 'block' }}>
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">Join Room</h3>
        <p className="text-muted font-weight-bold">Enter your email & Room Code to join room</p>
      </div>

      {/*begin::Form*/}
      <form
        id="kt_login_signin_form"
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        onSubmit={handleSubmit}
      >
        {/* begin: Email */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Email"
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 `}
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </div>
        {/* end: Email */}

        {/* begin: Password */}
        {showPassword && (
          <div className="form-group fv-plugins-icon-container">
            <input
              placeholder="Password"
              type="password"
              className={`form-control form-control-solid h-auto py-5 px-6`}
              onChange={e => setPassword(e.target.value)}
            />
          </div>
        )}
        {/* end: Password */}

        {/* begin: Username */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="User Name (Optional)"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6`}
            value={userName}
            onChange={e => setUserName(e.target.value)}
          />
        </div>
        {/* end: Username */}

        {/* begin: RoomCode */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Room Code"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6`}
            value={roomCode}
            onChange={e => setRoomCode(e.target.value)}
          />
        </div>
        {/* end: RoomCode */}

        <div className="form-group d-flex flex-wrap justify-content-between">
          <button type="submit" className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4">
            <span>Join Now</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>

          <Link to="/login">
            <button type="button" className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4">
              Cancel
            </button>
          </Link>
        </div>
      </form>
      {/*end::Form*/}
      <ToastContainer autoClose={2500} />
    </div>
  );
}

export default JoinRoom;
