import React, { useContext } from 'react';
import { Button } from '@material-ui/core';
import { HelperContext } from '../contexts/HelperContext';

const TakeScreenshotButton = () => {
  const { takeScreenshot } = useContext(HelperContext);

  return (
    <div className="camera-icon">
      <Button className="btn btn-success border-0 rounded-0 p-3" onClick={() => takeScreenshot()}>
        <i className="fas fa-camera m-0"></i>
      </Button>
    </div>
  );
};

export default TakeScreenshotButton;
