import React, { useContext } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { GlobalRoomContext } from '../contexts/GlobalRoomContext';
import { WorkshopContext } from '../contexts/WorkshopContext';

const JoinTableDropdownButton = ({ currentWorkshop }) => {
  const { globalRoomName, changeRoom, peermode, broadcast, isHost } = useContext(GlobalRoomContext);
  const { tables } = useContext(WorkshopContext);

  if (!peermode || (tables && !tables.length) || !isHost || broadcast) {
    return null;
  }

  return (
    <Dropdown style={{ top: '-2px' }}>
      <Dropdown.Toggle className="btn btn-success" id="dropdown-basic" style={{ top: '-2px' }} title="Join Table">
        <i className="fas fa-border-none"></i>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {tables &&
          tables.map(table => (
            <Dropdown.Item key={table.id} onClick={() => changeRoom(globalRoomName + ' | ' + table.title)}>
              {table.title}
            </Dropdown.Item>
          ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default JoinTableDropdownButton;
