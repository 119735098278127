import React, { useCallback, useContext, useState } from 'react';
import { ParticipantDetailsContext } from '../contexts/ParticipantDetailsContext';
import { Button, Card, CardContent, Divider, Typography } from '@material-ui/core';
import { GlobalRoomContext } from '../contexts/GlobalRoomContext';
import { Modal } from 'react-bootstrap';
import ParticipantTablesSelect from '../pages/ParticipantTablesSelect';
import { CurrentActiveRoomContext } from '../contexts/CurrentActiveRoomContext';
import { WorkshopContext } from '../contexts/WorkshopContext';
import { toast } from 'react-toastify';

const TableRandomizationButton = () => {
  const { participantDetails } = useContext(ParticipantDetailsContext);
  const { isBreak, isHost, peermode } = useContext(GlobalRoomContext);
  const [open, setOpen] = useState(false);
  const [breakType, setBreakType] = useState('random');
  const [participantsPerTable, setParticipantsPerTable] = useState(4);
  const [onlyJoined, setOnlyJoined] = useState(true);
  const [tableCount, setTableCount] = useState(2);
  const [removeOldTables, setRemoveOldTables] = useState(true);
  const { joinedParticipants } = useContext(CurrentActiveRoomContext);
  const { tables, currentWorkshop } = useContext(WorkshopContext);
  const [participantsTables, setParticipantsTables] = useState([]);

  const storeParticipantsTable = (participantId, tableId) => {
    // Get only Filtered records without the paticipant that selected now for add later
    let participantTableUnique = participantsTables.filter(pt => pt.participantId !== participantId);
    setParticipantsTables([...participantTableUnique, { participantId, tableId }]);
  };

  const breakTypeHandler = status => {
    setBreakType(status);
  };

  const handleParticipantsPerTableChange = useCallback(event => {
    setParticipantsPerTable(event.target.value);
  }, []);

  const handleTableCountChange = useCallback(event => {
    setTableCount(event.target.value);
  }, []);

  // Dynamically Break Out the Workshop's Tables API call
  const BreakOutDynamically = () => {
    fetch(process.env.REACT_APP_API_URL + '/dynamically-break-tables', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + participantDetails?.token,
      },
      body: JSON.stringify({
        workshopId: currentWorkshop.id,
        count: participantsPerTable,
        onlyJoined: onlyJoined ? 1 : 0,
        joinedParticipants: joinedParticipants,
      }),
    }).then(response => {
      setOpen(false);
    });
    // Get the LocalDataTrack that we published to the room.
    // const [localDataTrackPublication] = [...room.localParticipant.dataTracks.values()];
    // localDataTrackPublication.track.send("Educator Breaked Out Rooms Dynamically");
  };

  //Create Tables
  const CreateTables = () => {
    fetch(process.env.REACT_APP_API_URL + '/create-tables', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + participantDetails?.token,
      },
      body: JSON.stringify({
        workshopId: currentWorkshop.id,
        removeOldTables: removeOldTables ? 1 : 0,
        tableCount: tableCount,
      }),
    })
      .then(res => {
        if (!res.ok) {
          // error coming back from server
          throw Error('Something went wrong! Please, Try again later.');
        }
        return res.json();
      })
      .then(data => {
        toast.success(data.data.message, { position: 'top-center' });
      });
  };

  //Enroll Participants To Tables
  const enrollParticipantsToTables = () => {
    fetch(process.env.REACT_APP_API_URL + '/enroll-participants-to-tables', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + participantDetails?.token,
      },
      body: JSON.stringify({
        workshopId: currentWorkshop.id,
        participantsTables,
      }),
    })
      .then(res => {
        if (!res.ok) {
          // error coming back from server
          throw Error('Something went wrong! Please, Try again later.');
        }
        return res.json();
      })
      .then(data => {
        toast.success(data.data.message, { position: 'top-center' });
      });
  };

  return (
    <>
      {!isBreak && isHost && !peermode && (
        <Button className="btn btn-success" onClick={() => setOpen(true)} title="Table Randomization">
          <i className="fas fa-users"></i>
        </Button>
      )}

      {/* Modal for Table Randomization */}
      <Modal show={open} onHide={() => setOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Randomize Participants In Current Meeting!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group row mt-4 justify-content-between">
            <label className="col-form-label col-4">Break Out Type</label>
            <div className="col-7 justify-content-between">
              <input
                type="radio"
                name="Random"
                checked={breakType === 'random'}
                onClick={e => breakTypeHandler('random')}
              />{' '}
              Random <br />
              <input
                type="radio"
                name="Manual"
                checked={breakType === 'manual'}
                onClick={e => breakTypeHandler('manual')}
              />{' '}
              Manual
            </div>
          </div>
          <Divider />
          {breakType === 'random' ? (
            <>
              <div className="form-group row mt-4 justify-content-between">
                <label className="col-form-label col-4">Participants Per Group</label>
                <div className="col-7">
                  <input
                    type="text"
                    placeholder="Participants Per Group"
                    className="form-control form-control-lg form-control-line"
                    value={participantsPerTable}
                    onChange={handleParticipantsPerTableChange}
                  />
                </div>
              </div>
              <div className="form-group row mt-4 mb-0 justify-content-between">
                <label className="col-4 col-form-label">Only Joined Participants?</label>
                <div className="col-7">
                  <label className="checkbox checkbox-outline checkbox-success">
                    <input
                      type="checkbox"
                      className="form-control form-control-line form-control-lg"
                      defaultChecked={onlyJoined}
                      onChange={() => setOnlyJoined(!onlyJoined)}
                    />
                    <span></span>
                  </label>
                  <span className="form-text">If checked then randomize works only with joined participants.</span>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="form-group row mt-4 justify-content-between">
                <label className="col-form-label col-4">Table Count</label>
                <div className="col-7">
                  <input
                    type="text"
                    placeholder="Table Count"
                    className="form-control form-control-lg form-control-line"
                    value={tableCount}
                    onChange={handleTableCountChange}
                  />
                </div>
              </div>
              <div className="form-group row mt-4 mb-0 justify-content-between">
                <label className="col-4 col-form-label">Remove Old Tables?</label>
                <div className="col-7">
                  <label className="checkbox checkbox-outline checkbox-success">
                    <input
                      type="checkbox"
                      className="form-control form-control-line form-control-lg"
                      defaultChecked={removeOldTables}
                      onChange={() => setRemoveOldTables(!removeOldTables)}
                    />
                    <span></span>
                  </label>
                  <span className="form-text">If checked then existing tables will be removed.</span>
                  <div className="my-4">
                    <Button className="btn btn-success" onClick={() => CreateTables()}>
                      Create Tables
                    </Button>
                  </div>
                </div>
              </div>
              {tables && tables.length && currentWorkshop && currentWorkshop.all_associate_participants ? (
                <>
                  <div className="mt-5">
                    <Card
                      style={{ backgroundColor: 'rgb(44 188 152)', boxShadow: '1px 1px 5px grey' }}
                      className="mb-2"
                    >
                      <CardContent style={{ padding: '7px' }}>
                        <div className="row">
                          <div style={{ textAlign: 'center' }} className="col-6">
                            <Typography variant="h6" style={{ position: 'relative', color: 'white' }}>
                              Participants of the Workshop
                            </Typography>
                          </div>
                          <div style={{ textAlign: 'center' }} className="col-6">
                            <Typography variant="h6" style={{ position: 'relative', color: 'white' }}>
                              Select Table
                            </Typography>
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                    {currentWorkshop.all_associate_participants.map(participant => (
                      <ParticipantTablesSelect
                        key={participant.id}
                        participant={participant}
                        tables={tables}
                        currentWorkshop={currentWorkshop}
                        joinedParticipants={joinedParticipants}
                        storeParticipantsTable={storeParticipantsTable}
                      />
                    ))}
                  </div>
                  <div className="my-4 d-flex justify-content-center">
                    <Button className="btn btn-success" onClick={() => enrollParticipantsToTables()}>
                      <i className="fas fa-sync-alt"></i> Synchronize
                    </Button>
                  </div>
                </>
              ) : null}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-danger mr-3" onClick={() => setOpen(false)}>
            Close
          </Button>
          {breakType === 'random' ? (
            <Button className="btn btn-success" onClick={() => BreakOutDynamically()}>
              Randomize
            </Button>
          ) : null}
        </Modal.Footer>
      </Modal>
      {/* END: Modal for Table Randomization */}
    </>
  );
};

export default TableRandomizationButton;
