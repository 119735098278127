import React, { createContext, useContext, useState } from 'react';
import { ParticipantDetailsContext } from './ParticipantDetailsContext';

export const WorkshopContext = createContext();
const WorkshopContextProvider = props => {
  const { participantDetails } = useContext(ParticipantDetailsContext);
  const [tables, setTables] = useState([]);
  const [quizzes, setQuizzes] = useState([]);
  const [activeQuiz, setActiveQuiz] = useState(null);
  const [sliders, setSliders] = useState([]);
  const [activeSlider, setActiveSlider] = useState(null);
  const [polls, setPolls] = useState([]);
  const [activePoll, setActivePoll] = useState(null);
  const [currentWorkshop, setCurrentWorkshop] = useState([]);

  /**
   * Toggle Peer Mode
   */
  const togglePeerMode = () => {
    fetch(process.env.REACT_APP_API_URL + '/toggle-active-meeting-peermode', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + participantDetails?.token,
      },
      body: JSON.stringify({
        workshopId: currentWorkshop.id,
      }),
    });
  };
  /** END: Toggle Peer Mode */

  /**
   * Toggle Broadcast
   */
  const toggleBroadcast = () => {
    fetch(process.env.REACT_APP_API_URL + '/toggle-active-meeting-broadcast', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + participantDetails?.token,
      },
      body: JSON.stringify({
        workshopId: currentWorkshop.id,
      }),
    });
  };
  /** END: Toggle Broadcast */

  return (
    <WorkshopContext.Provider
      value={{
        togglePeerMode,
        toggleBroadcast,
        setTables,
        tables,
        currentWorkshop,
        setCurrentWorkshop,
        quizzes,
        setQuizzes,
        activeQuiz,
        setActiveQuiz,
        sliders,
        setSliders,
        activeSlider,
        setActiveSlider,
        polls,
        setPolls,
        activePoll,
        setActivePoll,
      }}
    >
      {props.children}
    </WorkshopContext.Provider>
  );
};

export default WorkshopContextProvider;
