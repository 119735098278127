import React, { useContext, useEffect, useState } from 'react';
import { Typography, Grid } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
// import { makeStyles, Theme, Button } from '@material-ui/core';
// import LocalVideoPreview from './LocalVideoPreview/LocalVideoPreview';
// import ToggleAudioButton from '../../Buttons/ToggleAudioButton/ToggleAudioButton';
// import ToggleVideoButton from '../../Buttons/ToggleVideoButton/ToggleVideoButton';
import { useAppState } from '../../../state';
// import useChatContext from '../../../hooks/useChatContext/useChatContext';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
// import Card from '@material-ui/core/Card';
// import CardActionArea from '@material-ui/core/CardActionArea';
// import CardActions from '@material-ui/core/CardActions';
// import CardContent from '@material-ui/core/CardContent';
import { ParticipantDetailsContext } from '../../../app/contexts/ParticipantDetailsContext';
// import { ToggleMeetingContext } from '../../../app/contexts/ToggleMeetingContext';
import { GlobalRoomContext } from '../../../app/contexts/GlobalRoomContext';
import useCurrentMeetWorkshopData from '../../../app/hooks/useCurrentMeetWorkshopData';

// const useStyles = makeStyles((theme: Theme) => ({
//   gutterBottom: {
//     marginBottom: '1em',
//   },
//   marginTop: {
//     marginTop: '1em',
//   },
//   deviceButton: {
//     width: '100%',
//     // border: '2px solid #aaa',
//     margin: '1em 10px',
//   },
//   localPreviewContainer: {
//     paddingRight: '2em',
//     [theme.breakpoints.down('sm')]: {
//       padding: '0 2.5em',
//     },
//   },
//   joinButtons: {
//     display: 'flex',
//     justifyContent: 'space-between',
//     [theme.breakpoints.down('sm')]: {
//       flexDirection: 'column-reverse',
//       width: '100%',
//       '& button': {
//         margin: '0.5em 0',
//       },
//     },
//   },
//   mobileButtonBar: {
//     [theme.breakpoints.down('sm')]: {
//       display: 'flex',
//       justifyContent: 'space-between',
//       margin: '1.5em 0 1em',
//     },
//   },
//   mobileButton: {
//     padding: '0.8em 0',
//     margin: 0,
//   },
//   card: {
//     maxWidth: 345,
//   },
//   media: {
//     height: 140,
//   },
//   root: {
//     flexGrow: 1,
//   },
//   paper: {
//     padding: theme.spacing(2),
//     textAlign: 'center',
//     color: theme.palette.text.secondary,
//   },
// }));

interface DeviceSelectionScreenProps {
  name: string;
  roomName: string;
}

export default function DeviceSelectionScreen({ name, roomName }: DeviceSelectionScreenProps) {
  // const classes = useStyles();
  const [checking, setChecking] = useState(true);
  const { getToken, isFetching } = useAppState();
  const { connect: videoConnect, isAcquiringLocalTracks, isConnecting, room, localTracks } = useVideoContext();
  // const disableButtons = isFetching || isAcquiringLocalTracks || isConnecting || checking;
  // const { setIsMeetingStarted, removeActiveMeeting } = useContext(ToggleMeetingContext);
  const { participantDetails } = useContext(ParticipantDetailsContext);
  const { globalRoomName, globalSubRoomName, setGlobalSubRoomName } = useContext(GlobalRoomContext);
  const [once, setOnce] = useState(false);
  const enableJoin =
    !isFetching && !isAcquiringLocalTracks && !isConnecting && !checking && !room && localTracks.length;
  const { workshopId } = useCurrentMeetWorkshopData();

  const handleJoin = () => {
    setOnce(true);
    let roomName = globalSubRoomName ? globalSubRoomName : globalRoomName;
    getToken(name, roomName).then(({ token }) => {
      localStorage.setItem('chatToken', token);
      videoConnect(token);
    });
  };

  // For late comers to check if the breakout started then assign them to the an table and join direct there.
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      fetch(process.env.REACT_APP_API_URL + '/current-meeting-workshop', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + participantDetails?.token,
        },
        body: JSON.stringify({ workshopId, roomName: globalRoomName }),
      })
        .then(response => {
          if (!response.ok)
            throw Error("Couldn't load data from " + process.env.REACT_APP_API_URL + '/current-meeting-workshop');
          return response.json();
        })
        .then(result => {
          isMounted = false;
          let workshop = result.data;
          if (
            workshop &&
            workshop.peer_mode === 1 &&
            workshop.enrolled_table_name !== null &&
            workshop.enrolled_table_name !== '' &&
            !workshop.enrolled_table_name.includes('unJoined')
          ) {
            setGlobalSubRoomName(globalRoomName + ' | ' + workshop.enrolled_table_name);
          }

          //If Active meeting not exist then redirect to the home
          if (workshop && workshop.active_meeting === null) {
            window.location.reload();
          }
          setChecking(false);
        })
        .catch(error => {
          isMounted = false;
          setChecking(false);
        });
    }
  }, []);

  useEffect(() => {
    if (enableJoin && !once) {
      handleJoin();
    }
  }, [enableJoin]);

  if (!enableJoin) {
    return (
      <Grid container justifyContent="center" alignItems="center" direction="column" style={{ height: '100%' }}>
        <div>
          <CircularProgress variant="indeterminate" />
        </div>
        <div>
          <Typography variant="body2" style={{ fontWeight: 'bold', fontSize: '16px' }}>
            Joining {globalSubRoomName ? globalSubRoomName : globalRoomName}
          </Typography>
        </div>
      </Grid>
    );
  }

  // const handleCancel = () => {
  //   removeActiveMeeting(roomName);
  //   setIsMeetingStarted(false);
  // };

  return (
    <>
      {/* <div className={classes.root}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={6}>
            <Card>
              <CardActionArea>
                <LocalVideoPreview identity={name} />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Hello, {name}
                  </Typography>
                  <Typography variant="subtitle1" color="textSecondary" component="p">
                    You are now joining as <b>{participantDetails.role}</b> in meeting. (
                    {globalSubRoomName ? globalSubRoomName : globalRoomName})<br />
                    <div style={{ marginRight: '10px' }} className="d-inline">
                      <ToggleAudioButton disabled={disableButtons} />
                    </div>
                    <ToggleVideoButton disabled={disableButtons} />
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button size="large" variant="outlined" color="primary" onClick={() => handleCancel()}>
                  Cancel
                </Button>
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  data-cy-join-now
                  style={{ color: 'white' }}
                  onClick={handleJoin}
                  disabled={disableButtons}
                >
                  Join Now
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </div> */}
    </>
  );
}
