import { useContext } from 'react';
import useVideoContext from '../useVideoContext/useVideoContext';
import useDominantSpeaker from '../useDominantSpeaker/useDominantSpeaker';
import useParticipants from '../useParticipants/useParticipants';
import useScreenShareParticipant from '../useScreenShareParticipant/useScreenShareParticipant';
import useSelectedParticipant from '../../components/VideoProvider/useSelectedParticipant/useSelectedParticipant';
import { GlobalRoomContext } from '../../app/contexts/GlobalRoomContext';

export default function useMainParticipant() {
  const [selectedParticipant] = useSelectedParticipant();
  const screenShareParticipant = useScreenShareParticipant();
  const dominantSpeaker = useDominantSpeaker();
  const participants = useParticipants();
  const { room } = useVideoContext();
  const localParticipant = room?.localParticipant;
  const remoteScreenShareParticipant = screenShareParticipant !== localParticipant ? screenShareParticipant : null;
  const { promotedParticipant } = useContext(GlobalRoomContext);

  let firstTeacher = null;
  for (let i = 0; i < participants.length; i++) {
    if (participants[i].identity.includes('educator')) {
      firstTeacher = participants[i];
      break;
    }
  }

  if (firstTeacher === null && localParticipant?.identity.includes('educator')) {
    firstTeacher = localParticipant;
  }

  let isPromotedParticipant = null;
  if (promotedParticipant !== null) {
    for (let i = 0; i < participants.length; i++) {
      if (participants[i].identity.includes(promotedParticipant)) {
        isPromotedParticipant = participants[i];
        break;
      }
    }
    if (isPromotedParticipant === null && localParticipant?.identity.includes(promotedParticipant)) {
      isPromotedParticipant = localParticipant;
    }
  }

  // The participant that is returned is displayed in the main video area. Changing the order of the following
  // variables will change the how the main speaker is determined.
  return (
    selectedParticipant ||
    remoteScreenShareParticipant ||
    isPromotedParticipant ||
    dominantSpeaker ||
    firstTeacher ||
    participants[0] ||
    localParticipant
  );
}
