import React, { useContext } from 'react';
import Participant from '../Participant/Participant';
import useMainParticipant from '../../hooks/useMainParticipant/useMainParticipant';
import useParticipants from '../../hooks/useParticipants/useParticipants';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import useSelectedParticipant from '../VideoProvider/useSelectedParticipant/useSelectedParticipant';
import useScreenShareParticipant from '../../hooks/useScreenShareParticipant/useScreenShareParticipant';
import { WorkshopContext } from '../../app/contexts/WorkshopContext';
import { GlobalRoomContext } from '../../app/contexts/GlobalRoomContext';

export default function ParticipantList({ sameTableParticipants = [], showOnlyTable = false }) {
  const enrolledTableParticipants: string[] = sameTableParticipants;
  const { room } = useVideoContext();
  const localParticipant = room!.localParticipant;
  const participants = useParticipants();
  const [selectedParticipant, setSelectedParticipant] = useSelectedParticipant();
  const screenShareParticipant = useScreenShareParticipant();
  const mainParticipant = useMainParticipant();
  const { currentWorkshop } = useContext(WorkshopContext);
  const { peermode, promotedParticipant } = useContext(GlobalRoomContext);

  // if (participants.length === 0) return null; // Don't render this component if there are no remote participants.

  return (
    <>
      <Participant participant={localParticipant} isLocalParticipant={true} />
      {participants.map(participant => {
        const isSelected = participant === selectedParticipant;
        const hideParticipant =
          participant === mainParticipant && participant !== screenShareParticipant && !isSelected;
        return (
          <>
            {/*Show only table and with audio also*/}
            {showOnlyTable && enrolledTableParticipants.includes(participant.identity) ? (
              <Participant
                key={participant.sid}
                participant={participant}
                isSelected={participant === selectedParticipant}
                setSelectedParticipant={setSelectedParticipant}
                hideParticipant={hideParticipant}
              />
            ) : null}

            {/*Audio enable for the author and promoted in main room in break room for all*/}
            {!showOnlyTable ? (
              <Participant
                key={participant.sid}
                participant={participant}
                videoOnly={!peermode && !participant.identity.includes(promotedParticipant) && !participant.identity.includes(currentWorkshop.created_by) ? true : false}
                isSelected={participant === selectedParticipant}
                setSelectedParticipant={setSelectedParticipant}
                hideParticipant={hideParticipant}
              />
            ) : null}
          </>
        );
      })}
    </>
  );
}
