import React from 'react';
import { ListItem } from '@material-ui/core';

const ChatItem = ({ message, name }) => {
  const styles = {
    listItem: isOwnMessage => ({
      flexDirection: 'column',
      alignItems: isOwnMessage ? 'flex-end' : 'flex-start',
      paddingRight: 5,
      paddingLeft: 0,
    }),
    container: isOwnMessage => ({
      maxWidth: '80%',
      borderRadius: 10,
      padding: '5px 10px',
      color: isOwnMessage ? 'white' : '#2cbc98',
      fontSize: 12,
      backgroundColor: isOwnMessage ? '#249479' : '#e0e1e2',
    }),
    author: { fontSize: 10, color: 'gray' },
    // timestamp: { fontSize: 8, color: "white", textAlign: "right", paddingTop: 4 },
  };
  const isOwnMessage = message.author === name ? true : false;

  return (
    <ListItem style={styles.listItem(isOwnMessage)}>
      <div style={styles.author}>{message.author}</div>
      <div style={styles.container(isOwnMessage)}>
        {message.body}
        {/* <div style={styles.timestamp}>
                    {new Date(message.dateCreated.toISOString()).toLocaleString()}
                </div> */}
      </div>
    </ListItem>
  );
};

export default ChatItem;
