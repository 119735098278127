import { useContext, useEffect } from 'react';
import { DataTrack as IDataTrack } from 'twilio-video';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { GlobalRoomContext } from '../../app/contexts/GlobalRoomContext';

export default function DataTrack({ track }: { track: IDataTrack }) {
  const { localTracks } = useVideoContext();
  const { setPromotedParticipant, setHostMutedAll } = useContext(GlobalRoomContext);

  useEffect(() => {
    const HandleMessage = (message: string) => {
      if (message === 'demoteParticipant') {
        setPromotedParticipant(null);
      }

      if (message.includes('promotedParticipant : ')) {
        let name = message.replace('promotedParticipant : ', '');
        setPromotedParticipant(name);
      }

      if (message === 'muteAll') {
        const audioTrack = localTracks.find(track => track.kind === 'audio');
        if (audioTrack) {
          audioTrack.disable();
        }
        setHostMutedAll(true);
        message = 'Host has muted all participants';
        toast(message, { position: 'top-center' });
      }
      if (message === 'unmuteAll') {
        const audioTrack = localTracks.find(track => track.kind === 'audio');
        if (audioTrack) {
          audioTrack.enable();
        }
        setHostMutedAll(false);
        message = 'Host has unmuted all participants';
        toast(message, { position: 'top-center' });
      }
    };
    track.on('message', HandleMessage);
    return () => {
      track.off('message', HandleMessage);
    };
  }, [track]);

  return null; // This component does not return any HTML, so we will return 'null' instead.
}
