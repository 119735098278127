import React, { useState, useEffect, useContext } from 'react';
import DeviceSelectionScreen from './DeviceSelectionScreen/DeviceSelectionScreen';
import MediaErrorSnackbar from './MediaErrorSnackbar/MediaErrorSnackbar';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { ParticipantDetailsContext } from '../../app/contexts/ParticipantDetailsContext';
import { GlobalRoomContext } from '../../app/contexts/GlobalRoomContext';

export enum Steps {
  roomNameStep,
  deviceSelectionStep,
}

export default function PreJoinScreens() {
  const { participantDetails } = useContext(ParticipantDetailsContext);
  const { getAudioAndVideoTracks } = useVideoContext();
  const { globalRoomName } = useContext(GlobalRoomContext);

  const [name] = useState<string>(participantDetails.name);
  const [roomName] = useState<string>(globalRoomName);

  const [mediaError, setMediaError] = useState<Error>();

  useEffect(() => {
    if (!mediaError) {
      getAudioAndVideoTracks().catch(error => {
        console.log('Error acquiring local media:');
        console.dir(error);
        setMediaError(error);
      });
    }
  }, [getAudioAndVideoTracks, mediaError]);

  return (
    <div>
      <MediaErrorSnackbar error={mediaError} />
      <DeviceSelectionScreen name={name} roomName={roomName} />
    </div>
  );
}
