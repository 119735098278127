import React, { useContext, useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import { WorkshopContext } from '../contexts/WorkshopContext';
import { GlobalRoomContext } from '../contexts/GlobalRoomContext';

const BroadcastButton = () => {
  const [loading, setLoading] = useState(false);
  const { toggleBroadcast } = useContext(WorkshopContext);
  const { isBreak, peermode, broadcast, isHost } = useContext(GlobalRoomContext);
  const toggleBroadcastButtonClass = broadcast ? `btn btn-danger` : `btn btn-success`;

  const BroadcastChange = () => {
    setLoading(true);
    toggleBroadcast();
  };

  useEffect(() => {
    setLoading(false);
  }, [broadcast]);

  if (!isHost || !peermode || isBreak) {
    return null;
  }

  return (
    <Button className={toggleBroadcastButtonClass} onClick={() => BroadcastChange()} disabled={loading} title="Toggle Broadcast">
      <i className="fas fa-bullhorn"></i>
      {loading && <span className="spinner spinner-gray" style={{ right: '40%' }}></span>}
    </Button>
  );
};

export default BroadcastButton;
