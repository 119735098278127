import React, { useContext } from 'react';
import { Container, Grid } from '@material-ui/core';
import VideoRoomMonitorButton from '../buttons/VideoRoomMonitorButton';
import RecordSessionButton from '../buttons/RecordSessionButton';
import EndCallButton from '../../components/Buttons/EndCallButton/EndCallButton';
import { GlobalRoomContext } from '../contexts/GlobalRoomContext';
import {WorkshopContext} from "../contexts/WorkshopContext";

const HeaderComponent = () => {
  const { globalRoomName, globalSubRoomName } = useContext(GlobalRoomContext);
  const { currentWorkshop } = useContext(WorkshopContext);

  return (
    <Container maxWidth="xl" className="header-buttons">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {/* Video Room Monitor Button  */}
          <VideoRoomMonitorButton />
          {/* END: Video Room Monitor Button  */}
          {/* Display Room Name */}
          Room : {globalSubRoomName ? globalSubRoomName : globalRoomName} ({currentWorkshop?.room_code})
          {/* END: Display Room Name */}
          {/* Record Session Button */}
          <RecordSessionButton />
          {/* END: Record Session Button */}
          {/* End Call Button */}
          <EndCallButton />
          {/* END: End Call Button */}
        </Grid>
      </Grid>
    </Container>
  );
};

export default HeaderComponent;
