import React, { useContext, useEffect, useState } from 'react';

import Button from '@material-ui/core/Button';
import MicIcon from '../../../icons/MicIcon';
import MicOffIcon from '../../../icons/MicOffIcon';

import useLocalAudioToggle from '../../../hooks/useLocalAudioToggle/useLocalAudioToggle';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { GlobalRoomContext } from '../../../app/contexts/GlobalRoomContext';

export default function ToggleAudioButton(props: { disabled?: boolean; className?: string }) {
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  const { localTracks } = useVideoContext();
  const hasAudioTrack = localTracks.some(track => track.kind === 'audio');
  const audioButtonToggleClass = isAudioEnabled ? `btn btn-success` : `btn btn-danger`;
  const { broadcast, hostMutedAll, peermode } = useContext(GlobalRoomContext);
  const [oldAudioState, setOldAudioState] = useState(isAudioEnabled);

  /* Do not add/append any dependencies instead of broadcast */
  useEffect(() => {
    if (broadcast && isAudioEnabled) {
      toggleAudioEnabled();
      setOldAudioState(true);
    } else {
      setOldAudioState(false);
    }

    // For change the audio state as old when the broadcast is off
    if (!broadcast) {
      if (oldAudioState && !isAudioEnabled) {
        toggleAudioEnabled();
      }
    }
  }, [broadcast]);

  // If host has muted all the participants and is not promoted then hide the audio toggle button in main room
  if (hostMutedAll && !peermode) {
    return null;
  }

  return (
    <Button className={audioButtonToggleClass} disabled={!hasAudioTrack || props.disabled} onClick={toggleAudioEnabled} title="Toggle Audio">
      {isAudioEnabled ? <MicIcon /> : <MicOffIcon />}
      {!hasAudioTrack ? 'No Audio' : ''}
      {/*{!hasAudioTrack ? 'No Audio' : isAudioEnabled ? 'Mute' : 'Unmute'}*/}
    </Button>
  );
}
