import React, { createContext, useState } from 'react';

export const GlobalRoomContext = createContext();

const GlobalRoomContextProvider = props => {
  const [globalRoomName, storeGlobalRoomName] = useState(null);
  const [globalSubRoomName, storeGlobalSubRoomName] = useState(null);
  const [isBreak, storeIsBreak] = useState(false);
  const [peermode, storePeermode] = useState(false);
  const [isHost, setIsHost] = useState(false);
  const [broadcast, storeBroadcast] = useState(false);
  const [hiddenRoomObject, storeHiddenRoomObject] = useState(null);
  const [roomObject, setRoomObject] = useState(null);
  const [promotedParticipant, storePromotedParticipant] = useState(null);
  const [hostMutedAll, storeHostMutedAll] = useState(false);
  const [hiddenRoomParticipants, storeHiddenRoomParticipants] = useState(null);

  const setGlobalRoomName = name => {
    storeGlobalRoomName(name);
    localStorage.setItem('current_room', name);
  };
  const setGlobalSubRoomName = subName => {
    storeGlobalSubRoomName(subName);
    localStorage.setItem('current_sub_room', subName);
  };
  const setIsBreak = value => {
    storeIsBreak(value);
  };
  const setPeermode = value => {
    storePeermode(value);
  };
  const setBroadcast = value => {
    storeBroadcast(value);
  };
  const setHiddenRoomObject = roomObject => {
    storeHiddenRoomObject(roomObject);
  };
  const setPromotedParticipant = value => {
    storePromotedParticipant(value);
  };
  const setHostMutedAll = value => {
    storeHostMutedAll(value);
  };
  const setHiddenRoomParticipants = participants => {
    storeHiddenRoomParticipants(participants);
  };

  /**
   * Room Changes Functions
   * ============================================
   */
  // Leave a room for join another one
  const leaveRoom = async roomName => {
    if (roomObject) {
      if (!isBreak) {
        // Join to the room and set the data for subroom
        setGlobalSubRoomName(roomName);
        setIsBreak(true);
      } else if (roomName !== globalRoomName) {
        // Change from one subroom to another subroom
        setGlobalSubRoomName(roomName);
        setIsBreak(true);
      } else if (isBreak && roomName === globalRoomName) {
        // Return to main room
        setGlobalSubRoomName(null);
        setIsBreak(false);
      }
      // Detach and remove all the tracks from the current room
      roomObject.localParticipant.tracks.forEach(publication => {
        if (publication.track.kind === 'audio' || publication.track.kind === 'video') {
          publication.track.stop();
          const attachedElements = publication.track.detach();
          attachedElements.forEach(element => element.remove());
        }
      });
      await roomObject.disconnect();
    }
  };
  // Change the room
  const changeRoom = async room_name => {
    // Disconnect fully from the room you're in currently before joining the next one automatic from the DeviceSelectionScreen
    await leaveRoom(room_name);
  };
  /** END: Room Changes Function */

  return (
    <GlobalRoomContext.Provider
      value={{
        globalRoomName,
        setGlobalRoomName,
        globalSubRoomName,
        setGlobalSubRoomName,
        isBreak,
        setIsBreak,
        peermode,
        setPeermode,
        broadcast,
        setBroadcast,
        hiddenRoomObject,
        setHiddenRoomObject,
        roomObject,
        setRoomObject,
        changeRoom,
        leaveRoom,
        isHost,
        setIsHost,
        promotedParticipant,
        setPromotedParticipant,
        hostMutedAll,
        setHostMutedAll,
        hiddenRoomParticipants,
        setHiddenRoomParticipants
      }}
    >
      {props.children}
    </GlobalRoomContext.Provider>
  );
};

export default GlobalRoomContextProvider;
