import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import useParticipantDetails from './useParticipantDetails';

export default function useFetchData(API_END_POINT, METHOD) {
  const { participantDetails } = useParticipantDetails();

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;
    async function fetchData() {
      if (isMounted) {
        // console.log('Fetching data');
        fetch(process.env.REACT_APP_API_URL + '/' + API_END_POINT, {
          method: METHOD,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + participantDetails?.token,
          },
        })
          .then(response => {
            // console.log('response', response);
            if (!response.ok)
              throw Error("Couldn't load data from " + process.env.REACT_APP_API_URL + '/' + API_END_POINT);
            return response.json();
          })
          .then(result => {
            setIsLoading(false);
            setError(null);
            if (API_END_POINT === 'get-student-meetings') {
              setData(result);
            } else {
              setData(result.data);
            }
          })
          .catch(error => {
            setIsLoading(false);
            setData(null);
            setError(error.message);
            toast.error(error.message);
          });
      }
    }
    fetchData(); // for the first render

    // For refresh every 10 seconds
    const interval = setInterval(() => {
      fetchData();
    }, 10000);

    // For refresh on every minute start
    /*function setMyFunctionInterval() {
      var currentDateSeconds = new Date().getSeconds();
      if (currentDateSeconds === 0) {
        setInterval(fetchData, 60000);
      } else {
        setTimeout(function() {
          setMyFunctionInterval();
        }, (60 - currentDateSeconds) * 1000);
      }
      fetchData();
    }
    const interval = setMyFunctionInterval();*/

    return () => {
      // console.log('exit');
      clearInterval(interval);
      isMounted = false; // For prevent after the component did unmount
    };
  }, [API_END_POINT, METHOD, participantDetails]);

  return { data, isLoading, error };
}
