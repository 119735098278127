import React from 'react';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  chatLoader: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '20px',
    paddingTop: '10px',
    paddingBottom: '10px',
    height: '20.88vh',
  },
}));

const ChatListingLoader = ({ message, type }) => {
  const classes = useStyles();

  return (
    <div className={classes.chatLoader}>
      <Loader
        type={type ? type : `Watch`} // Audio, Bars, Ball-Triangle, Circles, Grid, Hearts, Oval, Puff, Rings, TailSpin, Watch, ThreeDots
        color="#17c191"
        height={26}
        width={26}
      />
      {message ? message : `Please wait`}...
      <Loader
        type={type ? type : `Watch`} // Audio, Bars, Ball-Triangle, Circles, Grid, Hearts, Oval, Puff, Rings, TailSpin, Watch, ThreeDots
        color="#17c191"
        height={26}
        width={26}
      />
    </div>
  );
};

export default ChatListingLoader;

//USAGE
/* type: Audio, Bars, Ball-Triangle, Circles, Grid, Hearts, Oval, Puff, Rings, TailSpin, Watch, ThreeDots*/
// <TableListingLoader message="Please wait" type="ThreeDots" />
