import React, { useContext } from 'react';
import ToggleScreenShareButton from '../../components/Buttons/ToogleScreenShareButton/ToggleScreenShareButton';
import { Button } from '@material-ui/core';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { isMobile } from '../../utils';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import { GlobalRoomContext } from '../contexts/GlobalRoomContext';
import { ParticipantDetailsContext } from '../contexts/ParticipantDetailsContext';

const ScreenShareButton = () => {
  const roomState = useRoomState();
  const { isSharingScreen, toggleScreenShare } = useVideoContext();
  const isReconnecting = roomState === 'reconnecting';
  const { peermode, promotedParticipant } = useContext(GlobalRoomContext);
  const { participantDetails } = useContext(ParticipantDetailsContext);

  const iAmPromoted = !!(promotedParticipant && promotedParticipant.includes(participantDetails.name));

  if (isMobile) {
    return null;
  }

  // Hide the Screen Share button from the main room for the participants except promoted participant
  if (!peermode && participantDetails?.role !== 'educator' && !iAmPromoted) {
    return null;
  }

  return (
    <>
      {!isSharingScreen ? (
        <ToggleScreenShareButton disabled={isReconnecting} />
      ) : (
        <Button className="btn btn-danger" onClick={() => toggleScreenShare()} title="Stop Share">
          <i className="fas fa-share-square"></i>
        </Button>
      )}
    </>
  );
};

export default ScreenShareButton;
