import React, { useContext } from 'react';
import { Button } from '@material-ui/core';
import { ParticipantDetailsContext } from '../contexts/ParticipantDetailsContext';
import { VideoRoomMonitor } from '@twilio/video-room-monitor';

const VideoRoomMonitorButton = () => {
  const { participantDetails } = useContext(ParticipantDetailsContext);

  if (participantDetails?.role !== 'educator') {
    return null;
  }

  return (
    <Button
      size="small"
      color="primary"
      onClick={() => {
        VideoRoomMonitor.toggleMonitor();
      }}
    >
      {/* <i className="fas fa-info"></i> */}
    </Button>
  );
};

export default VideoRoomMonitorButton;
