import React, { Suspense } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { LayoutSplashScreen, ContentRoute } from '../_metronic/layout';
import { Dashboard as EducatorDashboard } from './pages/educator/Dashboard';
import { Home as StudentHome } from './pages/student/Home';
import { Index as EducatorMeetings } from './pages/educator/meetings/Index';
import UnsupportedBrowserWarning from '../components/UnsupportedBrowserWarning/UnsupportedBrowserWarning';
import AppStateProvider from '../state';
import ParticipantDetailsContextProvider from './contexts/ParticipantDetailsContext';
import ToggleMeetingContextProvider from './contexts/ToggleMeetingContext';
import { Index as StudentMeetings } from './pages/student/meetings/Index';
import GlobalRoomContextProvider from './contexts/GlobalRoomContext';
import useParticipantDetails from './hooks/useParticipantDetails';
import ChatMessagesContextProvider from './contexts/ChatMessagesContext';
import HelperContextProvider from './contexts/HelperContext';
import WorkshopContextProvider from './contexts/WorkshopContext';
import CurrentActiveRoomContextProvider from './contexts/CurrentActiveRoomContext';
import { JoinNow as JoinRoom } from "./pages/JoinNow";

export default function BasePage() {
  const { participantDetails } = useParticipantDetails();

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <UnsupportedBrowserWarning>
        <ParticipantDetailsContextProvider>
          <GlobalRoomContextProvider>
            <ToggleMeetingContextProvider>
              <ChatMessagesContextProvider>
                <WorkshopContextProvider>
                  <HelperContextProvider>
                    <CurrentActiveRoomContextProvider>
                      <AppStateProvider>
                        <Switch>
                          {/* Join Room with Code fot the Logged in Participants */}
                          <ContentRoute exact path="/join-room" component={JoinRoom} />

                          {/* Redirect from root URL to /dashboard. */}
                          {participantDetails?.role === 'educator' && (
                            <>
                              <Redirect exact from="/" to="/manage-meetings" />
                              <ContentRoute path="/dashboard" component={EducatorDashboard} />
                              <ContentRoute path="/manage-meetings" component={EducatorMeetings} />
                              {/*<ContentRoute path="/room/:URLRoomName" component={EducatorMeetings}/>*/}
                            </>
                          )}

                          {participantDetails?.role === 'student' && (
                            <>
                              <Redirect exact from="/" to="/my-meetings" />
                              <ContentRoute path="/home" component={StudentHome} />
                              <ContentRoute path="/my-meetings" component={StudentMeetings} />
                            </>
                          )}

                          <Redirect to="/error/error-v1" />
                        </Switch>
                      </AppStateProvider>
                    </CurrentActiveRoomContextProvider>
                  </HelperContextProvider>
                </WorkshopContextProvider>
              </ChatMessagesContextProvider>
            </ToggleMeetingContextProvider>
          </GlobalRoomContextProvider>
        </ParticipantDetailsContextProvider>
      </UnsupportedBrowserWarning>
    </Suspense>
  );
}
