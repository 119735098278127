import React, { useState } from 'react';
import DeviceSelectionDialog from '../../components/DeviceSelectionDialog/DeviceSelectionDialog';
import { Button } from '@material-ui/core';

const DeviceSettingsButton = () => {
  const [settingsOpen, setSettingsOpen] = useState(false);

  return (
    <>
      <Button className="btn btn-success" onClick={() => setSettingsOpen(true)} title="Device Settings">
        <i className="fa fa-cog"></i>
      </Button>

      {/* Modal for device selection */}
      <DeviceSelectionDialog
        open={settingsOpen}
        onClose={() => {
          setSettingsOpen(false);
        }}
      />
      {/* END:Modal for device selection */}
    </>
  );
};

export default DeviceSettingsButton;
