import React, { createContext, useState } from 'react';

export const ParticipantDetailsContext = createContext();

const ParticipantDetailsContextProvider = props => {
  function getParticipantDetails() {
    const userData =
      localStorage.getItem('participantDetails') !== null
        ? localStorage.getItem('participantDetails')
        : sessionStorage.getItem('participantDetails');
    return userData ? JSON.parse(userData) : [];
  }

  const [participantDetails] = useState(getParticipantDetails());

  return (
    <ParticipantDetailsContext.Provider value={{ participantDetails }}>
      {props.children}
    </ParticipantDetailsContext.Provider>
  );
};

export default ParticipantDetailsContextProvider;
